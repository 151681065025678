
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


table.column-right td:nth-child(2) {
    text-align: right;
}

.image-shopping {
    background-image: url("../img/undraw_web_shopping_re_owap.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image-receipt {
    background-image: url("../img/undraw_receipt_re_fre3.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image-banking {
    background-image: url("../img/undraw_online_banking_jy4.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.image-click {
    background-image: url("../img/click-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image-download {
    background-image: url("../img/download-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image-piggybank {
    background-image: url("../img/piggy-bank.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.transparent-header div{
    background-color: transparent !important;
}

/*
 see https://tailwindcss.com/docs/reusing-styles#extracting-classes-with-apply
*/

.shape-rendering-crisp {
    shape-rendering: crispEdges;
}

.image-rendering-optimize {
    image-rendering: optimizeSpeed;
}


@layer base {
    .content-width-predefined {
        @apply p-3 xl:p-0 max-w-5xl mx-auto w-full;
    }
}

body {
    font-family: 'Work Sans', 'sans-serif';
    
    font-weight: 400; 

    /*src: url('../fonts/WorkSans-Regular.woff2') format('woff2');*/
   
}


/*
 needed by PrimeVuew UI library
*/
:root {
    /* --primary-50: 236 253 245;
    --primary-100: 209 250 229;
    --primary-200: 167 243 208;
    --primary-300: 110 231 183;
    --primary-400: 52 211 153;
    --primary-500: 16 185 129;
    --primary-600: 5 150 105;
    --primary-700: 4 120 87;
    --primary-800: 6 95 70;
    --primary-900: 4 78 56;
    --primary-950: 2 44 34; */
    --primary-50: #91c2f8;
    --primary-100: #7ab6f6;
    --primary-200: #64aaf5;
    --primary-300: #4e9df3;
    --primary-400: #2285f0;
    --primary-500: #1f78d8;
    --primary-600: #1b6ac0;
    --primary-700: #185da8;
    --primary-800: #114378;
    --primary-900: #071b30;
    --primary-950: #030d18;
    --surface-0: 255 255 255;
    --surface-50: 248 250 252;
    --surface-100: 241 245 249;
    --surface-200: 226 232 240;
    --surface-300: 203 213 225;
    --surface-400: 148 163 184;
    --surface-500: 100 116 139;
    --surface-600: 71 85 105;
    --surface-700: 45 55 72;
    --surface-800: 30 41 59;
    --surface-900: 15 23 42;
    --surface-950: 3 6 23;
}


