@import "https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: hsl(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsl(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --p: 211 79% 17%;
  --pf: 211 79% 10%;
  --sf: 211 87% 47%;
  --af: 358 78% 56%;
  --nf: 215 16% 51%;
  --b2: 0 0% 93%;
  --b3: 0 0% 86%;
  --bc: 146 0% 19%;
  --pc: 223 15% 83%;
  --sc: 224 88% 93%;
  --ac: 3 35% 14%;
  --nc: 216 8% 13%;
  --inc: 202 34% 14%;
  --suc: 152 31% 13%;
  --wac: 39 47% 13%;
  --erc: 3 29% 15%;
  --s: 211 87% 54%;
  --a: 358 78% 63%;
  --n: 215 16% 58%;
  --b1: 0 0% 100%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --rounded-box: 0rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  border: 0;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  border: 0;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

input[type="range"]:focus {
  outline: none;
}

.content-width-predefined {
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding: .75rem;
}

@media (width >= 1280px) {
  .content-width-predefined {
    padding: 0;
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 320px) {
  .container {
    max-width: 320px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.daisyui-badge {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-badge, 1.9rem);
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.daisyui-btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  text-transform: var(--btn-text-case, uppercase);
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  outline-color: hsl(var(--bc) / 1);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.daisyui-btn-disabled, .daisyui-btn[disabled], .daisyui-btn:disabled {
  pointer-events: none;
}

.daisyui-btn-group > input[type="radio"].daisyui-btn {
  appearance: none;
}

.daisyui-btn-group > input[type="radio"].daisyui-btn:before {
  content: attr(data-title);
}

.daisyui-btninput[type="checkbox"], .daisyui-btninput[type="radio"] {
  appearance: none;
  width: auto;
}

.daisyui-btninput[type="checkbox"]:after, .daisyui-btninput[type="radio"]:after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.daisyui-card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
}

.daisyui-card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.daisyui-card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.daisyui-card-body :where(p) {
  flex-grow: 1;
}

.daisyui-card-actions {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.daisyui-card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.daisyui-card.daisyui-image-full {
  display: grid;
}

.daisyui-card.daisyui-image-full:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  opacity: .75;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
}

.daisyui-card.daisyui-image-full:before, .daisyui-card.daisyui-image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.daisyui-card.daisyui-image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.daisyui-card.daisyui-image-full > .daisyui-card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  position: relative;
}

.daisyui-collapse:not(td):not(tr):not(colgroup) {
  visibility: visible;
}

.daisyui-collapse {
  border-radius: var(--rounded-box, 1rem);
  grid-template-rows: auto 0fr;
  width: 100%;
  transition: grid-template-rows .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.daisyui-collapse-title, .daisyui-collapse > input[type="checkbox"], .daisyui-collapse > input[type="radio"], .daisyui-collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.daisyui-collapse > input[type="checkbox"], .daisyui-collapse > input[type="radio"] {
  appearance: none;
  opacity: 0;
}

.daisyui-collapse-content {
  visibility: hidden;
  cursor: unset;
  grid-row-start: 2;
  grid-column-start: 1;
  min-height: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.daisyui-collapse[open], .daisyui-collapse-open, .daisyui-collapse:focus:not(.daisyui-collapse-close), .daisyui-collapse:not(.daisyui-collapse-close):has( > input[type="checkbox"]:checked), .daisyui-collapse:not(.daisyui-collapse-close):has( > input[type="radio"]:checked) {
  grid-template-rows: auto 1fr;
}

.daisyui-collapse[open] > .daisyui-collapse-content, .daisyui-collapse-open > .daisyui-collapse-content, .daisyui-collapse:focus:not(.daisyui-collapse-close) > .daisyui-collapse-content, .daisyui-collapse:not(.daisyui-collapse-close) > input[type="checkbox"]:checked ~ .daisyui-collapse-content, .daisyui-collapse:not(.daisyui-collapse-close) > input[type="radio"]:checked ~ .daisyui-collapse-content {
  visibility: visible;
  min-height: fit-content;
}

@media (hover: hover) {
  .daisyui-btn:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--b3) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }

  .daisyui-btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }

  .daisyui-btn-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--sf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--sf) / var(--tw-bg-opacity));
  }

  .daisyui-btn.daisyui-glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .daisyui-btn-outline:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--bc) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--b1) / var(--tw-text-opacity));
  }

  .daisyui-btn-outline.daisyui-btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--pc) / var(--tw-text-opacity));
  }

  .daisyui-btn-outline.daisyui-btn-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--sf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--sf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--sc) / var(--tw-text-opacity));
  }

  .daisyui-btn-outline.daisyui-btn-accent:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--af) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--af) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--ac) / var(--tw-text-opacity));
  }

  .daisyui-btn-outline.daisyui-btn-success:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--su) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--su) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--suc) / var(--tw-text-opacity));
  }

  .daisyui-btn-outline.daisyui-btn-info:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--in) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--in) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--inc) / var(--tw-text-opacity));
  }

  .daisyui-btn-outline.daisyui-btn-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--wa) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--wac) / var(--tw-text-opacity));
  }

  .daisyui-btn-outline.daisyui-btn-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--er) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--erc) / var(--tw-text-opacity));
  }

  .daisyui-btn-disabled:hover, .daisyui-btn[disabled]:hover, .daisyui-btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-bg-opacity: .2;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }

  .daisyui-btninput[type="checkbox"]:checked:hover, .daisyui-btninput[type="radio"]:checked:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }
}

.daisyui-indicator {
  width: max-content;
  display: inline-flex;
  position: relative;
}

.daisyui-indicator :where(.daisyui-indicator-item) {
  z-index: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  white-space: nowrap;
  position: absolute;
}

:where(.daisyui-menu li) .daisyui-badge {
  justify-self: end;
}

.daisyui-steps {
  counter-reset: step;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: inline-grid;
  overflow: auto hidden;
}

.daisyui-steps .daisyui-step {
  text-align: center;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  min-width: 4rem;
  display: grid;
}

.daisyui-badge-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.daisyui-badge-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.daisyui-badge-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
  border-color: #0000;
}

.daisyui-badge-outline.daisyui-badge-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.daisyui-badge-outline.daisyui-badge-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.daisyui-badge-outline.daisyui-badge-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.daisyui-btn:active:hover, .daisyui-btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

.daisyui-btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.daisyui-btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
  outline-color: hsl(var(--p) / 1);
}

.daisyui-btn-primary.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
}

.daisyui-btn-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
  outline-color: hsl(var(--s) / 1);
}

.daisyui-btn-secondary.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf) / var(--tw-bg-opacity));
}

.daisyui-btn.daisyui-glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.daisyui-btn.daisyui-glass.daisyui-btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.daisyui-btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-color: currentColor;
}

.daisyui-btn-outline.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-primary.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-secondary.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-accent.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-success.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-info.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-warning.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.daisyui-btn-outline.daisyui-btn-error.daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.daisyui-btn.daisyui-btn-disabled, .daisyui-btn[disabled], .daisyui-btn:disabled {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.daisyui-btn-group > input[type="radio"]:checked.daisyui-btn, .daisyui-btn-group > .daisyui-btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.daisyui-btn-group > input[type="radio"]:checked.daisyui-btn:focus-visible, .daisyui-btn-group > .daisyui-btn-active:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--p) / 1);
}

.daisyui-btninput[type="checkbox"]:checked, .daisyui-btninput[type="radio"]:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.daisyui-btninput[type="checkbox"]:checked:focus-visible, .daisyui-btninput[type="radio"]:checked:focus-visible {
  outline-color: hsl(var(--p) / 1);
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.daisyui-card :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: unset;
  border-end-start-radius: unset;
  overflow: hidden;
}

.daisyui-card :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.daisyui-card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.daisyui-card.daisyui-bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
}

.daisyui-card.daisyui-compact .daisyui-card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.daisyui-card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.daisyui-card.daisyui-image-full :where(figure) {
  border-radius: inherit;
  overflow: hidden;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

details.daisyui-collapse {
  width: 100%;
}

details.daisyui-collapse summary {
  outline-offset: 2px;
  outline: 2px solid #0000;
  display: block;
  position: relative;
}

details.daisyui-collapse summary::-webkit-details-marker {
  display: none;
}

.daisyui-collapse:focus-visible, .daisyui-collapse:has(.daisyui-collapse-title:focus-visible), .daisyui-collapse:has( > input[type="checkbox"]:focus-visible), .daisyui-collapse:has( > input[type="radio"]:focus-visible) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.daisyui-collapse-arrow > .daisyui-collapse-title:after {
  --tw-translate-y: -100%;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: 50%;
  right: 1.4rem;
  box-shadow: 2px 2px;
}

[dir="rtl"] .daisyui-collapse-arrow > .daisyui-collapse-title:after {
  --tw-rotate: -45deg;
}

.daisyui-collapse-plus > .daisyui-collapse-title:after {
  content: "+";
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: .9rem;
  right: 1.4rem;
}

.daisyui-collapse:not(.daisyui-collapse-open):not(.daisyui-collapse-close) > input[type="checkbox"], .daisyui-collapse:not(.daisyui-collapse-open):not(.daisyui-collapse-close) > input[type="radio"]:not(:checked), .daisyui-collapse:not(.daisyui-collapse-open):not(.daisyui-collapse-close) > .daisyui-collapse-title {
  cursor: pointer;
}

.daisyui-collapse:focus:not(.daisyui-collapse-open):not(.daisyui-collapse-close):not(.daisyui-collapse[open]) > .daisyui-collapse-title {
  cursor: unset;
}

.daisyui-collapse-title {
  position: relative;
}

:where(.daisyui-collapse > input[type="checkbox"]), :where(.daisyui-collapse > input[type="radio"]) {
  z-index: 1;
}

.daisyui-collapse-title, :where(.daisyui-collapse > input[type="checkbox"]), :where(.daisyui-collapse > input[type="radio"]) {
  width: 100%;
  min-height: 3.75rem;
  padding: 1rem 3rem 1rem 1rem;
  transition: background-color .2s ease-out;
}

.daisyui-collapse[open] > :where(.daisyui-collapse-content), .daisyui-collapse-open > :where(.daisyui-collapse-content), .daisyui-collapse:focus:not(.daisyui-collapse-close) > :where(.daisyui-collapse-content), .daisyui-collapse:not(.daisyui-collapse-close) > :where(input[type="checkbox"]:checked ~ .daisyui-collapse-content), .daisyui-collapse:not(.daisyui-collapse-close) > :where(input[type="radio"]:checked ~ .daisyui-collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.daisyui-collapse[open].daisyui-collapse-arrow > .daisyui-collapse-title:after, .daisyui-collapse-open.daisyui-collapse-arrow > .daisyui-collapse-title:after, .daisyui-collapse-arrow:focus:not(.daisyui-collapse-close) > .daisyui-collapse-title:after, .daisyui-collapse-arrow:not(.daisyui-collapse-close) > input[type="checkbox"]:checked ~ .daisyui-collapse-title:after, .daisyui-collapse-arrow:not(.daisyui-collapse-close) > input[type="radio"]:checked ~ .daisyui-collapse-title:after {
  --tw-translate-y: -50%;
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

[dir="rtl"] .daisyui-collapse[open].daisyui-collapse-arrow > .daisyui-collapse-title:after, [dir="rtl"] .daisyui-collapse-open.daisyui-collapse-arrow > .daisyui-collapse-title:after, [dir="rtl"] .daisyui-collapse-arrow:focus:not(.daisyui-collapse-close) .daisyui-collapse-title:after, [dir="rtl"] .daisyui-collapse-arrow:not(.daisyui-collapse-close) input[type="checkbox"]:checked ~ .daisyui-collapse-title:after {
  --tw-rotate: 135deg;
}

.daisyui-collapse[open].daisyui-collapse-plus > .daisyui-collapse-title:after, .daisyui-collapse-open.daisyui-collapse-plus > .daisyui-collapse-title:after, .daisyui-collapse-plus:focus:not(.daisyui-collapse-close) > .daisyui-collapse-title:after, .daisyui-collapse-plus:not(.daisyui-collapse-close) > input[type="checkbox"]:checked ~ .daisyui-collapse-title:after, .daisyui-collapse-plus:not(.daisyui-collapse-close) > input[type="radio"]:checked ~ .daisyui-collapse-title:after {
  content: "−";
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.daisyui-steps .daisyui-step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: .5rem;
  margin-left: -100%;
  top: 0;
}

.daisyui-steps .daisyui-step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  width: 2rem;
  height: 2rem;
  display: grid;
  position: relative;
}

.daisyui-steps .daisyui-step:first-child:before {
  content: none;
}

.daisyui-steps .daisyui-step[data-content]:after {
  content: attr(data-content);
}

.daisyui-steps .daisyui-step-neutral + .daisyui-step-neutral:before, .daisyui-steps .daisyui-step-neutral:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.daisyui-steps .daisyui-step-primary + .daisyui-step-primary:before, .daisyui-steps .daisyui-step-primary:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.daisyui-steps .daisyui-step-secondary + .daisyui-step-secondary:before, .daisyui-steps .daisyui-step-secondary:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.daisyui-steps .daisyui-step-accent + .daisyui-step-accent:before, .daisyui-steps .daisyui-step-accent:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.daisyui-steps .daisyui-step-info + .daisyui-step-info:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
}

.daisyui-steps .daisyui-step-info:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.daisyui-steps .daisyui-step-success + .daisyui-step-success:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
}

.daisyui-steps .daisyui-step-success:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.daisyui-steps .daisyui-step-warning + .daisyui-step-warning:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.daisyui-steps .daisyui-step-warning:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.daisyui-steps .daisyui-step-error + .daisyui-step-error:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.daisyui-steps .daisyui-step-error:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.daisyui-badge-sm {
  height: 1rem;
  padding-left: .438rem;
  padding-right: .438rem;
  font-size: .75rem;
  line-height: 1rem;
}

.daisyui-badge-lg {
  height: 1.5rem;
  padding-left: .688rem;
  padding-right: .688rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.daisyui-btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.daisyui-btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.daisyui-btn-square:where(.daisyui-btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.daisyui-btn-square:where(.daisyui-btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.daisyui-btn-circle:where(.daisyui-btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.daisyui-btn-circle:where(.daisyui-btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.daisyui-card-side {
  flex-direction: row;
  align-items: stretch;
}

.daisyui-card-side :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: unset;
  border-end-end-radius: unset;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.daisyui-card-side :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: unset;
  overflow: hidden;
}

.daisyui-card-side figure > * {
  max-width: unset;
}

:where(.daisyui-card-side figure > *) {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.daisyui-indicator :where(.daisyui-indicator-item) {
  --tw-translate-y: -50%;
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset: 0 0 auto auto;
}

.daisyui-indicator :where(.daisyui-indicator-item.daisyui-indicator-start) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 0;
  right: auto;
}

.daisyui-indicator :where(.daisyui-indicator-item.daisyui-indicator-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 50%;
  right: 50%;
}

.daisyui-indicator :where(.daisyui-indicator-item.daisyui-indicator-end) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: auto;
  right: 0;
}

.daisyui-indicator :where(.daisyui-indicator-item.daisyui-indicator-bottom) {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: auto;
  bottom: 0;
}

.daisyui-indicator :where(.daisyui-indicator-item.daisyui-indicator-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  bottom: 50%;
}

.daisyui-indicator :where(.daisyui-indicator-item.daisyui-indicator-top) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  bottom: auto;
}

.daisyui-steps-horizontal .daisyui-step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.daisyui-steps-vertical {
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
}

.daisyui-steps-vertical .daisyui-step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.daisyui-btn-group .daisyui-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.daisyui-btn-group .daisyui-btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.daisyui-btn-group .daisyui-btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.daisyui-btn-group-horizontal .daisyui-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.daisyui-btn-group-horizontal .daisyui-btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.daisyui-btn-group-horizontal .daisyui-btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.daisyui-btn-group-vertical .daisyui-btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: -1px;
  margin-left: 0;
}

.daisyui-btn-group-vertical .daisyui-btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.daisyui-card-compact .daisyui-card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.daisyui-card-compact .daisyui-card-title {
  margin-bottom: .25rem;
}

.daisyui-card-normal .daisyui-card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.daisyui-card-normal .daisyui-card-title {
  margin-bottom: .75rem;
}

.daisyui-steps-horizontal .daisyui-step {
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  min-width: 4rem;
}

.daisyui-steps-horizontal .daisyui-step:before {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  width: 100%;
  height: .5rem;
  margin-left: -100%;
}

.daisyui-steps-vertical .daisyui-step {
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
  min-height: 4rem;
}

.daisyui-steps-vertical .daisyui-step:before {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: .5rem;
  height: 100%;
  margin-left: 50%;
}

[dir="rtl"] .daisyui-steps-vertical .daisyui-step:before {
  margin-right: auto;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.\!fixed {
  position: fixed !important;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.-bottom-\[47px\] {
  bottom: -47px;
}

.-left-\[15px\] {
  left: -15px;
}

.-left-\[9999px\] {
  left: -9999px;
}

.-top-\[18px\] {
  top: -18px;
}

.-top-\[21px\] {
  top: -21px;
}

.-top-\[35px\] {
  top: -35px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1\/2 {
  bottom: 50%;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-8 {
  bottom: 2rem;
}

.-left-1 {
  left: -.25rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-3 {
  left: .75rem;
}

.left-\[50\%\] {
  left: 50%;
}

.left-\[50px\] {
  left: 50px;
}

.left-\[calc\(50\%-1px\)\] {
  left: calc(50% - 1px);
}

.-right-1 {
  right: -.25rem;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-12 {
  right: 3rem;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-9 {
  right: 2.25rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-\[11px\] {
  top: 11px;
}

.top-\[13px\] {
  top: 13px;
}

.top-\[50\%\] {
  top: 50%;
}

.top-\[50px\] {
  top: 50px;
}

.top-full {
  top: 100%;
}

.\!z-40 {
  z-index: 40 !important;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[1035\] {
  z-index: 1035;
}

.z-\[1040\] {
  z-index: 1040;
}

.z-\[1065\] {
  z-index: 1065;
}

.z-\[1066\] {
  z-index: 1066;
}

.z-\[1070\] {
  z-index: 1070;
}

.z-\[1080\] {
  z-index: 1080;
}

.z-\[1100\] {
  z-index: 1100;
}

.z-\[2\] {
  z-index: 2;
}

.z-\[999\] {
  z-index: 999;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-first {
  order: -9999;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.\!-m-px {
  margin: -1px !important;
}

.-m-px {
  margin: -1px;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.\!my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-\[10px\] {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-\[15\%\] {
  margin-left: 15%;
  margin-right: 15%;
}

.mx-\[3px\] {
  margin-left: 3px;
  margin-right: 3px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-\[5px\] {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-ml-\[1\.5rem\] {
  margin-left: -1.5rem;
}

.-ml-px {
  margin-left: -1px;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.-mr-\[100\%\] {
  margin-right: -100%;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.mb-\[-0\.5715rem\] {
  margin-bottom: -.5715rem;
}

.mb-\[0\.125rem\] {
  margin-bottom: .125rem;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-60 {
  margin-left: 15rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[-0\.4715rem\] {
  margin-left: -.4715rem;
}

.ml-\[-0\.5715rem\] {
  margin-left: -.5715rem;
}

.ml-\[30px\] {
  margin-left: 30px;
}

.ml-\[3px\] {
  margin-left: 3px;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mr-\[6px\] {
  margin-right: 6px;
}

.mr-\[8px\] {
  margin-right: 8px;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-0\.5715rem\] {
  margin-top: -.5715rem;
}

.mt-\[0\.15rem\] {
  margin-top: .15rem;
}

.mt-auto {
  margin-top: auto;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.\!h-0 {
  height: 0 !important;
}

.\!h-px {
  height: 1px !important;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-144 {
  height: 36rem;
}

.h-16 {
  height: 4rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-2\/5 {
  height: 40%;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-3\/4 {
  height: 75%;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[0\.9375rem\] {
  height: .9375rem;
}

.h-\[1\.125rem\] {
  height: 1.125rem;
}

.h-\[1\.143rem\] {
  height: 1.143rem;
}

.h-\[1\.4rem\] {
  height: 1.4rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[160px\] {
  height: 160px;
}

.h-\[260px\] {
  height: 260px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[380px\] {
  height: 380px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[512px\] {
  height: 512px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[calc\(100\%-100px\)\] {
  height: calc(100% - 100px);
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-70vh {
  max-height: 70vh;
}

.max-h-\[150px\] {
  max-height: 150px;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.max-h-\[calc\(100\%-64px\)\] {
  max-height: calc(100% - 64px);
}

.max-h-full {
  max-height: 100%;
}

.min-h-1\/2 {
  min-height: 50%;
}

.min-h-70vh {
  min-height: 70vh;
}

.min-h-\[1\.5rem\] {
  min-height: 1.5rem;
}

.min-h-\[305px\] {
  min-height: 305px;
}

.min-h-\[325px\] {
  min-height: 325px;
}

.min-h-\[40px\] {
  min-height: 40px;
}

.min-h-\[auto\] {
  min-height: auto;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-16 {
  width: 4rem !important;
}

.\!w-px {
  width: 1px !important;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/5 {
  width: 20%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[0\.9375rem\] {
  width: .9375rem;
}

.w-\[1\%\] {
  width: 1%;
}

.w-\[1\.125rem\] {
  width: 1.125rem;
}

.w-\[1\.143rem\] {
  width: 1.143rem;
}

.w-\[1\.4rem\] {
  width: 1.4rem;
}

.w-\[125px\] {
  width: 125px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[15px\] {
  width: 15px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[280px\] {
  width: 280px;
}

.w-\[2px\] {
  width: 2px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[304px\] {
  width: 304px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[328px\] {
  width: 328px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[3rem\] {
  width: 3rem;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[45\%\] {
  width: 45%;
}

.w-\[4px\] {
  width: 4px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[6px\] {
  width: 6px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[72px\] {
  width: 72px;
}

.w-\[76px\] {
  width: 76px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[95px\] {
  width: 95px;
}

.w-\[calc\(100\%-100px\)\] {
  width: calc(100% - 100px);
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-80 {
  min-width: 20rem;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[310px\] {
  min-width: 310px;
}

.min-w-\[3rem\] {
  min-width: 3rem;
}

.min-w-\[48px\] {
  min-width: 48px;
}

.min-w-\[64px\] {
  min-width: 64px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-88 {
  max-width: 22rem;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[267px\] {
  max-width: 267px;
}

.max-w-\[325px\] {
  max-width: 325px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[calc\(100\%-1rem\)\] {
  max-width: calc(100% - 1rem);
}

.max-w-full {
  max-width: 100%;
}

.max-w-none {
  max-width: none;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-40 {
  flex-basis: 10rem;
}

.basis-64 {
  flex-basis: 16rem;
}

.basis-80 {
  flex-basis: 20rem;
}

.basis-auto {
  flex-basis: auto;
}

.origin-\[0_0\] {
  transform-origin: 0 0;
}

.origin-\[50\%_50\%\] {
  transform-origin: 50%;
}

.origin-\[center_bottom_0\] {
  transform-origin: center bottom 0;
}

.origin-bottom {
  transform-origin: bottom;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-44 {
  --tw-translate-x: -11rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-5 {
  --tw-translate-x: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[6px\] {
  --tw-translate-x: -6px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2 {
  --tw-translate-y: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-3 {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-5 {
  --tw-translate-y: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-6 {
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[5\%\] {
  --tw-translate-y: -5%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-20 {
  --tw-translate-x: 5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-32 {
  --tw-translate-x: 8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[150\%\] {
  --tw-translate-x: 150%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-10 {
  --tw-translate-y: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[50\%\] {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[6px\] {
  --tw-translate-y: 6px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.25\] {
  --tw-scale-x: .25;
  --tw-scale-y: .25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.02\] {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-\[0\.8\] {
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-\[fade-in_0\.15s_both\] {
  animation: .15s both fade-in;
}

.animate-\[fade-in_0\.3s_both\] {
  animation: .3s both fade-in;
}

.animate-\[fade-in_350ms_ease-in-out\] {
  animation: .35s ease-in-out fade-in;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-\[fade-out_0\.15s_both\] {
  animation: .15s both fade-out;
}

.animate-\[fade-out_0\.3s_both\] {
  animation: .3s both fade-out;
}

.animate-\[fade-out_350ms_ease-in-out\] {
  animation: .35s ease-in-out fade-out;
}

@keyframes progress {
  0% {
    transform: translateX(-45%);
  }

  100% {
    transform: translateX(100%);
  }
}

.animate-\[progress_3s_ease-in-out_infinite\] {
  animation: 3s ease-in-out infinite progress;
}

@keyframes show-up-clock {
  0% {
    opacity: 0;
    transform: scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-\[show-up-clock_350ms_linear\] {
  animation: .35s linear show-up-clock;
}

@keyframes slide-in-left {
  0% {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.animate-\[slide-in-left_0\.8s_both\] {
  animation: .8s both slide-in-left;
}

@keyframes slide-in-right {
  0% {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.animate-\[slide-in-right_0\.8s_both\] {
  animation: .8s both slide-in-right;
}

@keyframes slide-out-left {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate-\[slide-out-left_0\.8s_both\] {
  animation: .8s both slide-out-left;
}

@keyframes slide-out-right {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate-\[slide-out-right_0\.8s_both\] {
  animation: .8s both slide-out-right;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.animate-\[spinner-grow_0\.75s_linear_infinite\] {
  animation: .75s linear infinite spinner-grow;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-none {
  cursor: none;
}

.cursor-pointer {
  cursor: pointer;
}

.touch-none {
  touch-action: none;
}

.touch-pan-y {
  --tw-pan-y: pan-y;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-all {
  -webkit-user-select: all;
  user-select: all;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.list-image-\[url\(\'data\:image\/svg\+xml\;base64\,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4\=\'\)\] {
  list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4=");
}

.appearance-none {
  appearance: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.\!flex-row {
  flex-direction: row !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.\!overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.text-clip {
  text-overflow: clip;
}

.hyphens-auto {
  hyphens: auto;
}

.whitespace-normal {
  white-space: normal;
}

.\!whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[0\.25rem\] {
  border-radius: .25rem;
}

.rounded-\[0\.5rem\] {
  border-radius: .5rem;
}

.rounded-\[0\.6rem\] {
  border-radius: .6rem;
}

.rounded-\[100\%\] {
  border-radius: 100%;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[32px\] {
  border-radius: 32px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[999px\] {
  border-radius: 999px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-l-\[0\.25rem\] {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-r-\[0\.25rem\] {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-t-\[0\.6rem\] {
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.\!border-0 {
  border-width: 0 !important;
}

.\!border-\[3px\] {
  border-width: 3px !important;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[\.125rem\], .border-\[0\.125rem\] {
  border-width: .125rem;
}

.border-\[0\.15em\] {
  border-width: .15em;
}

.border-\[14px\] {
  border-width: 14px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-y-\[10px\] {
  border-top-width: 10px;
  border-bottom-width: 10px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-\[0\.125rem\] {
  border-left-width: .125rem;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.\!border-solid {
  border-style: solid !important;
}

.border-solid {
  border-style: solid;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.\!border-\[\#14a44d\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(20 164 77 / var(--tw-border-opacity)) !important;
}

.\!border-\[\#b2b3b4\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(178 179 180 / var(--tw-border-opacity)) !important;
}

.\!border-\[\#dc4c64\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(220 76 100 / var(--tw-border-opacity)) !important;
}

.border-\[\#14a44d\] {
  --tw-border-opacity: 1;
  border-color: rgb(20 164 77 / var(--tw-border-opacity));
}

.border-\[\#3b71ca\] {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.border-\[\#dc4c64\] {
  --tw-border-opacity: 1;
  border-color: rgb(220 76 100 / var(--tw-border-opacity));
}

.border-\[\#eee\] {
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-current {
  border-color: currentColor;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-monet-blue-dark {
  --tw-border-opacity: 1;
  border-color: rgb(9 42 78 / var(--tw-border-opacity));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.border-primary-300 {
  border-color: var(--primary-300);
}

.border-primary-400 {
  border-color: var(--primary-400);
}

.border-primary-500 {
  border-color: var(--primary-500);
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-surface-200 {
  border-color: rgb(var(--surface-200));
}

.border-surface-300 {
  border-color: rgb(var(--surface-300));
}

.border-surface-400 {
  border-color: rgb(var(--surface-400));
}

.border-surface-500 {
  border-color: rgb(var(--surface-500));
}

.border-surface-600 {
  border-color: rgb(var(--surface-600));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-transparent {
  border-top-color: #0000;
}

.\!bg-\[\#858585\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(133 133 133 / var(--tw-bg-opacity)) !important;
}

.\!bg-danger-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 229 233 / var(--tw-bg-opacity)) !important;
}

.\!bg-neutral-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.\!bg-primary-100 {
  background-color: var(--primary-100) !important;
}

.bg-\[\#00000012\] {
  background-color: #00000012;
}

.bg-\[\#00000066\] {
  background-color: #0006;
}

.bg-\[\#000000e6\] {
  background-color: #000000e6;
}

.bg-\[\#3b71ca\] {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

.bg-\[\#6d6d6d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(109 109 109 / var(--tw-bg-opacity));
}

.bg-\[\#aaa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(170 170 170 / var(--tw-bg-opacity));
}

.bg-\[\#eceff1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 241 / var(--tw-bg-opacity));
}

.bg-\[\#eee\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.bg-\[rgba\(0\,0\,0\,0\.4\)\] {
  background-color: #0006;
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/10 {
  background-color: #0000001a;
}

.bg-black\/40 {
  background-color: #0006;
}

.bg-blue-300\/20 {
  background-color: #93c5fd33;
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-200\/80 {
  background-color: #e5e7ebcc;
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-green-300\/20 {
  background-color: #86efac33;
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-monet-blue-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(9 42 78 / var(--tw-bg-opacity));
}

.bg-monet-blue-darker {
  --tw-bg-opacity: 1;
  background-color: rgb(6 29 54 / var(--tw-bg-opacity));
}

.bg-monet-blue-light {
  --tw-bg-opacity: 1;
  background-color: rgb(34 133 240 / var(--tw-bg-opacity));
}

.bg-monet-red {
  --tw-bg-opacity: 1;
  background-color: rgb(234 86 90 / var(--tw-bg-opacity));
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.bg-orange-300\/20 {
  background-color: #fdba7433;
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.bg-primary-100 {
  background-color: var(--primary-100);
}

.bg-primary-400 {
  background-color: var(--primary-400);
}

.bg-primary-50 {
  background-color: var(--primary-50);
}

.bg-primary-500 {
  background-color: var(--primary-500);
}

.bg-purple-300\/20 {
  background-color: #d8b4fe33;
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.bg-red-300\/20 {
  background-color: #fca5a533;
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-surface-0 {
  background-color: rgb(var(--surface-0));
}

.bg-surface-100 {
  background-color: rgb(var(--surface-100));
}

.bg-surface-200 {
  background-color: rgb(var(--surface-200));
}

.bg-surface-300\/20 {
  background-color: rgb(var(--surface-300) / .2);
}

.bg-surface-400 {
  background-color: rgb(var(--surface-400));
}

.bg-surface-50 {
  background-color: rgb(var(--surface-50));
}

.bg-surface-500 {
  background-color: rgb(var(--surface-500));
}

.bg-surface-600\/80 {
  background-color: rgb(var(--surface-600) / .8);
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/30 {
  background-color: #ffffff4d;
}

.bg-white\/80 {
  background-color: #fffc;
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}

.bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.bg-zinc-600\/50 {
  background-color: #52525b80;
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-gray-900 {
  --tw-gradient-from: #111827 var(--tw-gradient-from-position);
  --tw-gradient-to: #11182700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-\[\#afafaf\] {
  fill: #afafaf;
}

.fill-current {
  fill: currentColor;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[1rem\] {
  padding: 1rem;
}

.p-\[5px\] {
  padding: 5px;
}

.p-\[auto\] {
  padding: auto;
}

.\!py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.\!py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-\[0\.4rem\] {
  padding-left: .4rem;
  padding-right: .4rem;
}

.px-\[1\.4rem\] {
  padding-left: 1.4rem;
  padding-right: 1.4rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.px-\[auto\] {
  padding-left: auto;
  padding-right: auto;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[0\.32rem\] {
  padding-top: .32rem;
  padding-bottom: .32rem;
}

.py-\[0\.33rem\] {
  padding-top: .33rem;
  padding-bottom: .33rem;
}

.py-\[0\.4375rem\] {
  padding-top: .4375rem;
  padding-bottom: .4375rem;
}

.py-\[0\.4rem\] {
  padding-top: .4rem;
  padding-bottom: .4rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[5px\] {
  padding-bottom: 5px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[1\.5rem\] {
  padding-left: 1.5rem;
}

.pl-\[18px\] {
  padding-left: 18px;
}

.pl-\[50px\] {
  padding-left: 50px;
}

.pl-\[8px\] {
  padding-left: 8px;
}

.pr-0 {
  padding-right: 0;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-\[24px\] {
  padding-right: 24px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[0\.37rem\] {
  padding-top: .37rem;
}

.pt-\[6px\] {
  padding-top: 6px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.-indent-\[999px\] {
  text-indent: -999px;
}

.indent-2 {
  text-indent: .5rem;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-\[-0\.125em\] {
  vertical-align: -.125em;
}

.font-sans {
  font-family: Roboto, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[0\.8rem\] {
  font-size: .8rem;
}

.text-\[0\.9rem\] {
  font-size: .9rem;
}

.text-\[1\.1rem\] {
  font-size: 1.1rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[2\.5rem\] {
  font-size: 2.5rem;
}

.text-\[3\.75rem\] {
  font-size: 3.75rem;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.5\] {
  line-height: 1.5;
}

.leading-\[1\.6\] {
  line-height: 1.6;
}

.leading-\[2\.15\] {
  line-height: 2.15;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-\[normal\] {
  line-height: normal;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[-0\.00833em\] {
  letter-spacing: -.00833em;
}

.tracking-\[\.1rem\], .tracking-\[0\.1rem\] {
  letter-spacing: .1rem;
}

.tracking-\[1\.7px\] {
  letter-spacing: 1.7px;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-\[\#14a44d\] {
  --tw-text-opacity: 1 !important;
  color: rgb(20 164 77 / var(--tw-text-opacity)) !important;
}

.\!text-\[\#dc4c64\] {
  --tw-text-opacity: 1 !important;
  color: rgb(220 76 100 / var(--tw-text-opacity)) !important;
}

.\!text-danger-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(176 35 58 / var(--tw-text-opacity)) !important;
}

.\!text-gray-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 250 251 / var(--tw-text-opacity)) !important;
}

.\!text-primary {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--p) / var(--tw-text-opacity)) !important;
}

.\!text-primary-700 {
  color: var(--primary-700) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-\[\#14a44d\] {
  --tw-text-opacity: 1;
  color: rgb(20 164 77 / var(--tw-text-opacity));
}

.text-\[\#212529\] {
  --tw-text-opacity: 1;
  color: rgb(33 37 41 / var(--tw-text-opacity));
}

.text-\[\#3b71ca\] {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.text-\[\#4f4f4f\] {
  --tw-text-opacity: 1;
  color: rgb(79 79 79 / var(--tw-text-opacity));
}

.text-\[\#b3afaf\] {
  --tw-text-opacity: 1;
  color: rgb(179 175 175 / var(--tw-text-opacity));
}

.text-\[\#b3b3b3\] {
  --tw-text-opacity: 1;
  color: rgb(179 179 179 / var(--tw-text-opacity));
}

.text-\[\#dc4c64\] {
  --tw-text-opacity: 1;
  color: rgb(220 76 100 / var(--tw-text-opacity));
}

.text-\[\#ffffff8a\] {
  color: #ffffff8a;
}

.text-\[rgb\(220\,76\,100\)\] {
  --tw-text-opacity: 1;
  color: rgb(220 76 100 / var(--tw-text-opacity));
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/50 {
  color: #00000080;
}

.text-black\/\[64\] {
  color: #000;
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-danger {
  --tw-text-opacity: 1;
  color: rgb(220 76 100 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.text-monet-blue-dark {
  --tw-text-opacity: 1;
  color: rgb(9 42 78 / var(--tw-text-opacity));
}

.text-monet-blue-darker {
  --tw-text-opacity: 1;
  color: rgb(6 29 54 / var(--tw-text-opacity));
}

.text-monet-blue-light {
  --tw-text-opacity: 1;
  color: rgb(34 133 240 / var(--tw-text-opacity));
}

.text-monet-gray {
  --tw-text-opacity: 1;
  color: rgb(132 146 166 / var(--tw-text-opacity));
}

.text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.text-primary-400 {
  color: var(--primary-400);
}

.text-primary-500 {
  color: var(--primary-500);
}

.text-primary-600 {
  color: var(--primary-600);
}

.text-primary-700 {
  color: var(--primary-700);
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.text-surface-300 {
  color: rgb(var(--surface-300));
}

.text-surface-500 {
  color: rgb(var(--surface-500));
}

.text-surface-600 {
  color: rgb(var(--surface-600));
}

.text-surface-700 {
  color: rgb(var(--surface-700));
}

.text-surface-800 {
  color: rgb(var(--surface-800));
}

.text-surface-900 {
  color: rgb(var(--surface-900));
}

.text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/70 {
  color: #ffffffb3;
}

.text-white\/80 {
  color: #fffc;
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-auto {
  text-underline-offset: auto;
}

.\!opacity-0 {
  opacity: 0 !important;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-\[\.53\] {
  opacity: .53;
}

.opacity-\[\.54\] {
  opacity: .54;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0px_3px_0_rgba\(0\,0\,0\,0\.07\)\,0_2px_2px_0_rgba\(0\,0\,0\,0\.04\)\] {
  --tw-shadow: 0 0px 3px 0 #00000012, 0 2px 2px 0 #0000000a;
  --tw-shadow-colored: 0 0px 3px 0 var(--tw-shadow-color), 0 2px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_10px_15px_-3px_rgba\(0\,0\,0\,0\.07\)\,0_4px_6px_-2px_rgba\(0\,0\,0\,0\.05\)\] {
  --tw-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_2px_5px_0_rgba\(0\,0\,0\,0\.16\)\,_0_2px_10px_0_rgba\(0\,0\,0\,0\.12\)\] {
  --tw-shadow: 0 2px 5px 0 #00000029, 0 2px 10px 0 #0000001f;
  --tw-shadow-colored: 0 2px 5px 0 var(--tw-shadow-color), 0 2px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_4px_12px_0_rgba\(0\,0\,0\,0\.07\)\,_0_2px_4px_rgba\(0\,0\,0\,0\.05\)\] {
  --tw-shadow: 0 4px 12px 0 #00000012, 0 2px 4px #0000000d;
  --tw-shadow-colored: 0 4px 12px 0 var(--tw-shadow-color), 0 2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_4px_9px_-4px_\#3b71ca\] {
  --tw-shadow: 0 4px 9px -4px #3b71ca;
  --tw-shadow-colored: 0 4px 9px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_2px_15px_-3px_rgba\(0\,0\,0\,\.07\)\,_0px_10px_20px_-2px_rgba\(0\,0\,0\,\.04\)\] {
  --tw-shadow: 0px 2px 15px -3px #00000012, 0px 10px 20px -2px #0000000a;
  --tw-shadow-colored: 0px 2px 15px -3px var(--tw-shadow-color), 0px 10px 20px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-offset-0 {
  outline-offset: 0px;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-blue-300\/50 {
  --tw-ring-color: #93c5fd80;
}

.ring-green-300\/50 {
  --tw-ring-color: #86efac80;
}

.ring-orange-300\/50 {
  --tw-ring-color: #fdba7480;
}

.ring-purple-300\/50 {
  --tw-ring-color: #d8b4fe80;
}

.ring-red-300\/50 {
  --tw-ring-color: #fca5a580;
}

.ring-surface-300\/50 {
  --tw-ring-color: rgb(var(--surface-300) / .5);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-xl {
  --tw-blur: blur(24px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[background-color\,_opacity\] {
  transition-property: background-color, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[background-color\,box-shadow\,border\] {
  transition-property: background-color, box-shadow, border;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[height\] {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[opacity\] {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,_opacity\] {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,height\] {
  transition-property: transform, height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,opacity\] {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-\[0ms\] {
  transition-delay: 0s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-\[1000ms\] {
  transition-duration: 1s;
}

.duration-\[120ms\] {
  transition-duration: .12s;
}

.duration-\[150ms\] {
  transition-duration: .15s;
}

.duration-\[200ms\] {
  transition-duration: .2s;
}

.duration-\[250ms\] {
  transition-duration: .25s;
}

.duration-\[350ms\] {
  transition-duration: .35s;
}

.duration-\[400ms\] {
  transition-duration: .4s;
}

.duration-\[600ms\] {
  transition-duration: .6s;
}

.ease-\[cubic-bezier\(0\,0\,0\.15\,1\)\,_cubic-bezier\(0\,0\,0\.15\,1\)\] {
  transition-timing-function: cubic-bezier(0, 0, .15, 1), cubic-bezier(0, 0, .15, 1);
}

.ease-\[cubic-bezier\(0\,0\,0\.2\,1\)\] {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-\[cubic-bezier\(0\.25\,0\.1\,0\.25\,1\)\], .ease-\[cubic-bezier\(0\.25\,0\.1\,0\.25\,1\.0\)\] {
  transition-timing-function: ease;
}

.ease-\[cubic-bezier\(0\.4\,0\,0\.2\,1\)\] {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-\[ease\] {
  transition-timing-function: ease;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-transform {
  will-change: transform;
}

.\!\[clip\:rect\(0\,0\,0\,0\)\] {
  clip: rect(0, 0, 0, 0) !important;
}

.\[clip\:rect\(0\,0\,0\,0\)\] {
  clip: rect(0, 0, 0, 0);
}

.\[direction\:ltr\] {
  direction: ltr;
}

.\[overflow-anchor\:none\] {
  overflow-anchor: none;
}

.\[transition\:background-color_\.2s_linear\,_height_\.2s_ease-in-out\] {
  transition: background-color .2s linear, height .2s ease-in-out;
}

.\[transition\:background-color_\.2s_linear\,_width_\.2s_ease-in-out\,_opacity\] {
  transition: background-color .2s linear, width .2s ease-in-out, opacity;
}

.\[transition\:background-color_250ms_cubic-bezier\(0\.4\,0\,0\.2\,1\)_0ms\,box-shadow_250ms_cubic-bezier\(0\.4\,0\,0\.2\,1\)_0ms\,border_250ms_cubic-bezier\(0\.4\,0\,0\.2\,1\)_0ms\] {
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1), box-shadow .25s cubic-bezier(.4, 0, .2, 1), border .25s cubic-bezier(.4, 0, .2, 1);
}

table.column-right td:nth-child(2) {
  text-align: right;
}

.image-shopping {
  background-image: url("undraw_web_shopping_re_owap.4af5d017.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-receipt {
  background-image: url("undraw_receipt_re_fre3.f4e52fc4.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-banking {
  background-image: url("undraw_online_banking_jy4.716387fa.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-click {
  background-image: url("click-icon.ccf30843.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-download {
  background-image: url("download-icon.bf9c1c05.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-piggybank {
  background-image: url("piggy-bank.73962cb0.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.transparent-header div {
  background-color: #0000 !important;
}

.shape-rendering-crisp {
  shape-rendering: crispedges;
}

.image-rendering-optimize {
  image-rendering: optimizespeed;
}

body {
  font-family: Work Sans, "sans-serif";
  font-weight: 400;
}

:root {
  --primary-50: #91c2f8;
  --primary-100: #7ab6f6;
  --primary-200: #64aaf5;
  --primary-300: #4e9df3;
  --primary-400: #2285f0;
  --primary-500: #1f78d8;
  --primary-600: #1b6ac0;
  --primary-700: #185da8;
  --primary-800: #114378;
  --primary-900: #071b30;
  --primary-950: #030d18;
  --surface-0: 255 255 255;
  --surface-50: 248 250 252;
  --surface-100: 241 245 249;
  --surface-200: 226 232 240;
  --surface-300: 203 213 225;
  --surface-400: 148 163 184;
  --surface-500: 100 116 139;
  --surface-600: 71 85 105;
  --surface-700: 45 55 72;
  --surface-800: 30 41 59;
  --surface-900: 15 23 42;
  --surface-950: 3 6 23;
}

@media (width >= 768px) {
  .md\:content-width-predefined {
    width: 100%;
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding: .75rem;
  }

  @media (width >= 1280px) {
    .md\:content-width-predefined {
      padding: 0;
    }
  }

  .md\:daisyui-collapse:not(td):not(tr):not(colgroup) {
    visibility: visible;
  }

  .md\:daisyui-collapse {
    border-radius: var(--rounded-box, 1rem);
    grid-template-rows: auto 0fr;
    width: 100%;
    transition: grid-template-rows .2s;
    display: grid;
    position: relative;
    overflow: hidden;
  }

  .md\:daisyui-collapse > input[type="checkbox"], .md\:daisyui-collapse > input[type="radio"] {
    appearance: none;
    opacity: 0;
    grid-row-start: 1;
    grid-column-start: 1;
  }

  .md\:daisyui-collapse[open], .md\:daisyui-collapse:focus:not(.daisyui-collapse-close), .md\:daisyui-collapse:not(.daisyui-collapse-close):has( > input[type="checkbox"]:checked), .md\:daisyui-collapse:not(.daisyui-collapse-close):has( > input[type="radio"]:checked) {
    grid-template-rows: auto 1fr;
  }

  .md\:daisyui-collapse[open] > .daisyui-collapse-content, .md\:daisyui-collapse:focus:not(.daisyui-collapse-close) > .daisyui-collapse-content, .md\:daisyui-collapse:not(.daisyui-collapse-close) > input[type="checkbox"]:checked ~ .daisyui-collapse-content, .md\:daisyui-collapse:not(.daisyui-collapse-close) > input[type="radio"]:checked ~ .daisyui-collapse-content {
    visibility: visible;
    min-height: fit-content;
  }

  details.md\:daisyui-collapse {
    width: 100%;
  }

  details.md\:daisyui-collapse summary {
    outline-offset: 2px;
    outline: 2px solid #0000;
    display: block;
    position: relative;
  }

  details.md\:daisyui-collapse summary::-webkit-details-marker {
    display: none;
  }

  .md\:daisyui-collapse:focus-visible, .md\:daisyui-collapse:has(.daisyui-collapse-title:focus-visible), .md\:daisyui-collapse:has( > input[type="checkbox"]:focus-visible), .md\:daisyui-collapse:has( > input[type="radio"]:focus-visible) {
    outline-offset: 2px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: hsl(var(--bc) / 1);
  }

  .md\:daisyui-collapse-arrow > .daisyui-collapse-title:after {
    --tw-translate-y: -100%;
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    content: "";
    transform-origin: 75% 75%;
    pointer-events: none;
    width: .5rem;
    height: .5rem;
    transition-property: all;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    display: block;
    position: absolute;
    top: 50%;
    right: 1.4rem;
    box-shadow: 2px 2px;
  }

  [dir="rtl"] .md\:daisyui-collapse-arrow > .daisyui-collapse-title:after {
    --tw-rotate: -45deg;
  }

  .md\:daisyui-collapse:not(.daisyui-collapse-open):not(.daisyui-collapse-close) > input[type="checkbox"], .md\:daisyui-collapse:not(.daisyui-collapse-open):not(.daisyui-collapse-close) > input[type="radio"]:not(:checked), .md\:daisyui-collapse:not(.daisyui-collapse-open):not(.daisyui-collapse-close) > .daisyui-collapse-title {
    cursor: pointer;
  }

  .md\:daisyui-collapse:focus:not(.daisyui-collapse-open):not(.daisyui-collapse-close):not(.md\:daisyui-collapse[open]) > .daisyui-collapse-title {
    cursor: unset;
  }

  :where(.md\:daisyui-collapse > input[type="checkbox"]), :where(.md\:daisyui-collapse > input[type="radio"]) {
    z-index: 1;
    width: 100%;
    min-height: 3.75rem;
    padding: 1rem 3rem 1rem 1rem;
    transition: background-color .2s ease-out;
  }

  .md\:daisyui-collapse[open] > :where(.daisyui-collapse-content), .md\:daisyui-collapse:focus:not(.daisyui-collapse-close) > :where(.daisyui-collapse-content), .md\:daisyui-collapse:not(.daisyui-collapse-close) > :where(input[type="checkbox"]:checked ~ .daisyui-collapse-content), .md\:daisyui-collapse:not(.daisyui-collapse-close) > :where(input[type="radio"]:checked ~ .daisyui-collapse-content) {
    padding-bottom: 1rem;
    transition: padding .2s ease-out, background-color .2s ease-out;
  }

  .md\:daisyui-collapse[open].daisyui-collapse-arrow > .daisyui-collapse-title:after, .md\:daisyui-collapse-arrow.daisyui-collapse[open] > .daisyui-collapse-title:after, .md\:daisyui-collapse-arrow .daisyui-collapse-open > .daisyui-collapse-title:after, .md\:daisyui-collapse-arrow:focus:not(.daisyui-collapse-close) > .daisyui-collapse-title:after, .md\:daisyui-collapse-arrow:not(.daisyui-collapse-close) > input[type="checkbox"]:checked ~ .daisyui-collapse-title:after, .md\:daisyui-collapse-arrow:not(.daisyui-collapse-close) > input[type="radio"]:checked ~ .daisyui-collapse-title:after {
    --tw-translate-y: -50%;
    --tw-rotate: 225deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  [dir="rtl"] .md\:daisyui-collapse[open].daisyui-collapse-arrow > .daisyui-collapse-title:after, [dir="rtl"] .md\:daisyui-collapse-arrow.daisyui-collapse[open] > .daisyui-collapse-title:after, [dir="rtl"] .md\:daisyui-collapse-arrow.daisyui-collapse-open > .daisyui-collapse-title:after, [dir="rtl"] .md\:daisyui-collapse-arrow:focus:not(.daisyui-collapse-close) .daisyui-collapse-title:after, [dir="rtl"] .md\:daisyui-collapse-arrow:not(.daisyui-collapse-close) input[type="checkbox"]:checked ~ .daisyui-collapse-title:after {
    --tw-rotate: 135deg;
  }

  .md\:daisyui-collapse[open].daisyui-collapse-plus > .daisyui-collapse-title:after {
    content: "−";
  }

  .md\:daisyui-card-side {
    flex-direction: row;
    align-items: stretch;
  }

  .md\:daisyui-card-side :where(figure:first-child) {
    border-start-start-radius: inherit;
    border-start-end-radius: unset;
    border-end-end-radius: unset;
    border-end-start-radius: inherit;
    overflow: hidden;
  }

  .md\:daisyui-card-side :where(figure:last-child) {
    border-start-start-radius: unset;
    border-start-end-radius: inherit;
    border-end-end-radius: inherit;
    border-end-start-radius: unset;
    overflow: hidden;
  }

  .md\:daisyui-card-side figure > * {
    max-width: unset;
  }

  :where(.md\:daisyui-card-side figure > *) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .md\:daisyui-steps-horizontal {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    display: inline-grid;
    overflow: auto hidden;
  }

  .md\:daisyui-steps-horizontal .daisyui-step {
    text-align: center;
    grid-template-rows: 40px 1fr;
    grid-template-columns: auto;
    place-items: center;
    min-width: 4rem;
    display: grid;
  }

  .md\:daisyui-steps-horizontal .daisyui-step:before {
    --tw-translate-x: 0px;
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    content: "";
    width: 100%;
    height: .5rem;
    margin-left: -100%;
  }

  .md\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .md\:prose-lg :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .md\:prose-lg :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .md\:prose-lg :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-left: 1em;
  }

  .md\:prose-lg :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .md\:prose-lg :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .md\:prose-lg :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .md\:prose-lg :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .md\:prose-lg :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .md\:prose-lg :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .md\:prose-lg :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:prose-lg :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .md\:prose-lg :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .3125rem;
    padding: .222222em .444444em;
    font-size: .888889em;
  }

  .md\:prose-lg :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .md\:prose-lg :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .md\:prose-lg :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .875em;
  }

  .md\:prose-lg :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .md\:prose-lg :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .md\:prose-lg :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .md\:prose-lg :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .md\:prose-lg :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .md\:prose-lg :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .md\:prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .md\:prose-lg :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .md\:prose-lg :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .md\:prose-lg :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    padding-left: 1.55556em;
  }

  .md\:prose-lg :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .md\:prose-lg :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .md\:prose-lg :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .md\:prose-lg :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .md\:prose-lg :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-lg :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .md\:prose-lg :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .md\:prose-lg :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .md\:prose-lg :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .md\:prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding: .75em;
  }

  .md\:prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .md\:prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .md\:prose-lg :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .md\:prose-lg :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:prose-lg :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .md\:prose-lg :where(.md\:prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-lg :where(.md\:prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}

.marker\:text-black ::marker {
  color: #000;
}

.marker\:text-monet-blue-dark ::marker {
  color: #092a4e;
}

.marker\:text-monet-blue-light ::marker {
  color: #2285f0;
}

.marker\:text-black::marker {
  color: #000;
}

.marker\:text-monet-blue-dark::marker {
  color: #092a4e;
}

.marker\:text-monet-blue-light::marker {
  color: #2285f0;
}

.selection\:bg-transparent ::selection, .selection\:bg-transparent::selection {
  background-color: #0000;
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.placeholder\:text-gray-900::placeholder {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.placeholder\:text-surface-400::placeholder {
  color: rgb(var(--surface-400));
}

.before\:pointer-events-none:before {
  content: var(--tw-content);
  pointer-events: none;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:h-\[0\.875rem\]:before {
  content: var(--tw-content);
  height: .875rem;
}

.before\:w-\[0\.875rem\]:before {
  content: var(--tw-content);
  width: .875rem;
}

.before\:scale-0:before {
  content: var(--tw-content);
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-transparent:before {
  content: var(--tw-content);
  background-color: #0000;
}

.before\:text-amber-500:before {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:shadow-\[0px_0px_0px_13px_transparent\]:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px transparent;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[\'✔\'\]:before {
  --tw-content: "✔";
  content: var(--tw-content);
}

.after\:clear-both:after {
  content: var(--tw-content);
  clear: both;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:ml-0:first-child {
  margin-left: 0;
}

.first\:rounded-l-md:first-child {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.last\:rounded-r-md:last-child {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.last\:border-r:last-child {
  border-right-width: 1px;
}

.checked\:\!border-\[\#14a44d\]:checked {
  --tw-border-opacity: 1 !important;
  border-color: rgb(20 164 77 / var(--tw-border-opacity)) !important;
}

.checked\:\!border-\[\#dc4c64\]:checked {
  --tw-border-opacity: 1 !important;
  border-color: rgb(220 76 100 / var(--tw-border-opacity)) !important;
}

.checked\:border-primary:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.checked\:\!bg-\[\#14a44d\]:checked {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(20 164 77 / var(--tw-bg-opacity)) !important;
}

.checked\:\!bg-\[\#dc4c64\]:checked {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 76 100 / var(--tw-bg-opacity)) !important;
}

.checked\:bg-primary:checked {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.checked\:before\:opacity-\[0\.16\]:checked:before {
  content: var(--tw-content);
  opacity: .16;
}

.checked\:after\:absolute:checked:after {
  content: var(--tw-content);
  position: absolute;
}

.checked\:after\:-mt-px:checked:after {
  content: var(--tw-content);
  margin-top: -1px;
}

.checked\:after\:ml-\[0\.25rem\]:checked:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.checked\:after\:block:checked:after {
  content: var(--tw-content);
  display: block;
}

.checked\:after\:h-\[0\.8125rem\]:checked:after {
  content: var(--tw-content);
  height: .8125rem;
}

.checked\:after\:w-\[0\.375rem\]:checked:after {
  content: var(--tw-content);
  width: .375rem;
}

.checked\:after\:rotate-45:checked:after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:after\:border-\[0\.125rem\]:checked:after {
  content: var(--tw-content);
  border-width: .125rem;
}

.checked\:after\:border-l-0:checked:after {
  content: var(--tw-content);
  border-left-width: 0;
}

.checked\:after\:border-t-0:checked:after {
  content: var(--tw-content);
  border-top-width: 0;
}

.checked\:after\:border-solid:checked:after {
  content: var(--tw-content);
  border-style: solid;
}

.checked\:after\:border-white:checked:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.checked\:after\:\!bg-\[\#14a44d\]:checked:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1 !important;
  background-color: rgb(20 164 77 / var(--tw-bg-opacity)) !important;
}

.checked\:after\:\!bg-\[\#dc4c64\]:checked:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 76 100 / var(--tw-bg-opacity)) !important;
}

.checked\:after\:bg-transparent:checked:after {
  content: var(--tw-content);
  background-color: #0000;
}

.checked\:after\:content-\[\'\'\]:checked:after {
  --tw-content: "";
  content: var(--tw-content);
}

.empty\:hidden:empty {
  display: none;
}

.hover\:-translate-x-2:hover {
  --tw-translate-x: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:-translate-y-1:hover {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:translate-x-2:hover {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:rounded-\[50\%\]:hover {
  border-radius: 50%;
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

.hover\:border-primary-500:hover {
  border-color: var(--primary-500);
}

.hover\:border-primary-600:hover {
  border-color: var(--primary-600);
}

.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.hover\:border-surface-600:hover {
  border-color: rgb(var(--surface-600));
}

.hover\:\!bg-\[\#eee\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-\[\#00000014\]:hover {
  background-color: #00000014;
}

.hover\:bg-\[\#00000026\]:hover {
  background-color: #00000026;
}

.hover\:bg-\[unset\]:hover {
  background-color: unset;
}

.hover\:bg-amber-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.hover\:bg-blue-300\/20:hover {
  background-color: #93c5fd33;
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.hover\:bg-green-300\/20:hover {
  background-color: #86efac33;
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.hover\:bg-orange-300\/20:hover {
  background-color: #fdba7433;
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.hover\:bg-primary-500:hover {
  background-color: var(--primary-500);
}

.hover\:bg-primary-600:hover {
  background-color: var(--primary-600);
}

.hover\:bg-primary-700:hover {
  background-color: var(--primary-700);
}

.hover\:bg-purple-300\/20:hover {
  background-color: #d8b4fe33;
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.hover\:bg-red-300\/20:hover {
  background-color: #fca5a533;
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.hover\:bg-surface-100:hover {
  background-color: rgb(var(--surface-100));
}

.hover\:bg-surface-300\/20:hover {
  background-color: rgb(var(--surface-300) / .2);
}

.hover\:bg-surface-600:hover {
  background-color: rgb(var(--surface-600));
}

.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: .3;
}

.hover\:fill-\[\#8b8b8b\]:hover {
  fill: #8b8b8b;
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

.hover\:text-\[\#3b71ca\]:hover {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.hover\:text-\[\#8b8b8b\]:hover {
  --tw-text-opacity: 1;
  color: rgb(139 139 139 / var(--tw-text-opacity));
}

.hover\:text-amber-100:hover {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.hover\:text-monet-blue-light:hover {
  --tw-text-opacity: 1;
  color: rgb(34 133 240 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.hover\:text-primary-600:hover {
  color: var(--primary-600);
}

.hover\:text-slate-100:hover {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.hover\:text-surface-700:hover {
  color: rgb(var(--surface-700));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:\!opacity-90:hover {
  opacity: .9 !important;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:\!shadow-none:hover {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.3\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.2\)\]:hover {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:outline-none:hover {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.hover\:ease-in-out:hover {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:before\:opacity-\[0\.04\]:hover:before {
  content: var(--tw-content);
  opacity: .04;
}

.hover\:before\:shadow-\[0px_0px_0px_13px_rgba\(0\,0\,0\,0\.6\)\]:hover:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #0009;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:rounded-\[50\%\]:focus {
  border-radius: 50%;
}

.focus\:\!border-\[\#14a44d\]:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(20 164 77 / var(--tw-border-opacity)) !important;
}

.focus\:\!border-\[\#dc4c64\]:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(220 76 100 / var(--tw-border-opacity)) !important;
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.focus\:\!bg-\[\#eee\]:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity)) !important;
}

.focus\:bg-\[\#00000014\]:focus {
  background-color: #00000014;
}

.focus\:bg-\[\#00000026\]:focus {
  background-color: #00000026;
}

.focus\:bg-neutral-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.focus\:bg-primary-600:focus {
  background-color: var(--primary-600);
}

.focus\:bg-primary-700:focus {
  background-color: var(--primary-700);
}

.focus\:text-\[\#3b71ca\]:focus {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.focus\:text-primary:focus {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.focus\:text-primary-600:focus {
  color: var(--primary-600);
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:underline:focus {
  text-decoration-line: underline;
}

.focus\:\!opacity-90:focus {
  opacity: .9 !important;
}

.focus\:\!shadow-\[inset_0_0_0_1px_\#14a44d\]:focus {
  --tw-shadow: inset 0 0 0 1px #14a44d !important;
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:\!shadow-\[inset_0_0_0_1px_\#dc4c64\]:focus {
  --tw-shadow: inset 0 0 0 1px #dc4c64 !important;
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.3\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.2\)\]:focus {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-te-primary:focus {
  --tw-shadow: 0 0 0 1px #3b71ca;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-offset-0:focus {
  outline-offset: 0px;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-400\/50:focus {
  --tw-ring-color: #60a5fa80;
}

.focus\:ring-green-400\/50:focus {
  --tw-ring-color: #4ade8080;
}

.focus\:ring-orange-400\/50:focus {
  --tw-ring-color: #fb923c80;
}

.focus\:ring-purple-400\/50:focus {
  --tw-ring-color: #c084fc80;
}

.focus\:ring-red-400\/50:focus {
  --tw-ring-color: #f8717180;
}

.focus\:ring-surface-400\/50:focus {
  --tw-ring-color: rgb(var(--surface-400) / .5);
}

.focus\:transition-\[border-color_0\.2s\]:focus {
  transition-property: border-color .2s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focus\:placeholder\:opacity-100:focus::placeholder {
  opacity: 1;
}

.focus\:before\:scale-100:focus:before {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.focus\:before\:opacity-\[0\.12\]:focus:before {
  content: var(--tw-content);
  opacity: .12;
}

.focus\:before\:shadow-\[0px_0px_0px_13px_rgba\(0\,0\,0\,0\.6\)\]:focus:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #0009;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:before\:transition-\[box-shadow_0\.2s\,transform_0\.2s\]:focus:before {
  content: var(--tw-content);
  transition-property: box-shadow .2s, transform .2s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focus\:after\:absolute:focus:after {
  content: var(--tw-content);
  position: absolute;
}

.focus\:after\:z-\[1\]:focus:after {
  content: var(--tw-content);
  z-index: 1;
}

.focus\:after\:block:focus:after {
  content: var(--tw-content);
  display: block;
}

.focus\:after\:h-\[0\.875rem\]:focus:after {
  content: var(--tw-content);
  height: .875rem;
}

.focus\:after\:w-\[0\.875rem\]:focus:after {
  content: var(--tw-content);
  width: .875rem;
}

.focus\:after\:rounded-\[0\.125rem\]:focus:after {
  content: var(--tw-content);
  border-radius: .125rem;
}

.focus\:after\:content-\[\'\'\]:focus:after {
  --tw-content: "";
  content: var(--tw-content);
}

.checked\:focus\:before\:scale-100:focus:checked:before {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:focus\:before\:shadow-\[0px_0px_0px_13px_\#3b71ca\]:focus:checked:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #3b71ca;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.checked\:focus\:before\:transition-\[box-shadow_0\.2s\,transform_0\.2s\]:focus:checked:before {
  content: var(--tw-content);
  transition-property: box-shadow .2s, transform .2s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.checked\:focus\:after\:-mt-px:focus:checked:after {
  content: var(--tw-content);
  margin-top: -1px;
}

.checked\:focus\:after\:ml-\[0\.25rem\]:focus:checked:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.checked\:focus\:after\:h-\[0\.8125rem\]:focus:checked:after {
  content: var(--tw-content);
  height: .8125rem;
}

.checked\:focus\:after\:w-\[0\.375rem\]:focus:checked:after {
  content: var(--tw-content);
  width: .375rem;
}

.checked\:focus\:after\:rotate-45:focus:checked:after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:focus\:after\:rounded-none:focus:checked:after {
  content: var(--tw-content);
  border-radius: 0;
}

.checked\:focus\:after\:border-\[0\.125rem\]:focus:checked:after {
  content: var(--tw-content);
  border-width: .125rem;
}

.checked\:focus\:after\:border-l-0:focus:checked:after {
  content: var(--tw-content);
  border-left-width: 0;
}

.checked\:focus\:after\:border-t-0:focus:checked:after {
  content: var(--tw-content);
  border-top-width: 0;
}

.checked\:focus\:after\:border-solid:focus:checked:after {
  content: var(--tw-content);
  border-style: solid;
}

.checked\:focus\:after\:border-white:focus:checked:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.checked\:focus\:after\:bg-transparent:focus:checked:after {
  content: var(--tw-content);
  background-color: #0000;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:outline-offset-0:focus-visible {
  outline-offset: 0px;
}

.focus-visible\:ring:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-inset:focus-visible {
  --tw-ring-inset: inset;
}

.active\:bg-\[\#c4d4ef\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(196 212 239 / var(--tw-bg-opacity));
}

.active\:bg-\[\#cacfd1\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(202 207 209 / var(--tw-bg-opacity));
}

.active\:bg-primary-700:active {
  background-color: var(--primary-700);
}

.active\:bg-primary-800:active {
  background-color: var(--primary-800);
}

.active\:text-primary-700:active {
  color: var(--primary-700);
}

.active\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.3\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.2\)\]:active {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:shadow-lg:active {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.disabled\:text-slate-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.disabled\:hover\:bg-transparent:hover:disabled {
  background-color: #0000;
}

.group\/x:hover .group-hover\/x\:h-\[11px\] {
  height: 11px;
}

.group\/y:hover .group-hover\/y\:w-\[11px\] {
  width: 11px;
}

.group\/x:hover .group-hover\/x\:bg-\[\#999\], .group\/y:hover .group-hover\/y\:bg-\[\#999\] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.group\/ps:hover .group-hover\/ps\:opacity-60 {
  opacity: .6;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group\/x:focus .group-focus\/x\:h-\[0\.6875rem\] {
  height: .6875rem;
}

.group\/y:focus .group-focus\/y\:w-\[0\.6875rem\] {
  width: .6875rem;
}

.group\/x:focus .group-focus\/x\:bg-\[\#999\], .group\/y:focus .group-focus\/y\:bg-\[\#999\] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.group\/ps:focus .group-focus\/ps\:opacity-100 {
  opacity: 1;
}

.group\/ps:focus .group-focus\/ps\:opacity-60 {
  opacity: .6;
}

.group\/ps:active .group-active\/ps\:opacity-100 {
  opacity: 1;
}

.group\/ps.ps--active-x .group-\[\&\.ps--active-x\]\/ps\:block, .group\/ps.ps--active-y .group-\[\&\.ps--active-y\]\/ps\:block {
  display: block;
}

.group\/x.ps--clicking .group-\[\&\.ps--clicking\]\/x\:h-\[11px\] {
  height: 11px;
}

.group\/y.ps--clicking .group-\[\&\.ps--clicking\]\/y\:w-\[11px\] {
  width: 11px;
}

.group[data-te-datepicker-cell-current] .group-\[\[data-te-datepicker-cell-current\]\]\:border {
  border-width: 1px;
}

.group[data-te-datepicker-cell-current] .group-\[\[data-te-datepicker-cell-current\]\]\:border-solid {
  border-style: solid;
}

.group[data-te-datepicker-cell-current] .group-\[\[data-te-datepicker-cell-current\]\]\:border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.group\/ps.ps--active-x .group-\[\&\.ps--active-x\]\/ps\:bg-transparent, .group\/ps.ps--active-y .group-\[\&\.ps--active-y\]\/ps\:bg-transparent {
  background-color: #0000;
}

.group\/x.ps--clicking .group-\[\&\.ps--clicking\]\/x\:bg-\[\#999\], .group\/y.ps--clicking .group-\[\&\.ps--clicking\]\/y\:bg-\[\#999\] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.group:not([data-te-datepicker-cell-disabled]):not([data-te-datepicker-cell-selected]):hover .group-\[\:not\(\[data-te-datepicker-cell-disabled\]\)\:not\(\[data-te-datepicker-cell-selected\]\)\:hover\]\:bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.group:not([data-te-datepicker-cell-selected])[data-te-datepicker-cell-focused] .group-\[\:not\(\[data-te-datepicker-cell-selected\]\)\[data-te-datepicker-cell-focused\]\]\:bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.group[data-te-datepicker-cell-selected] .group-\[\[data-te-datepicker-cell-selected\]\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.group[data-te-datepicker-cell-selected] .group-\[\[data-te-datepicker-cell-selected\]\]\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group\/ps.ps--scrolling-x .group-\[\&\.ps--scrolling-x\]\/ps\:opacity-60, .group\/ps.ps--scrolling-y .group-\[\&\.ps--scrolling-y\]\/ps\:opacity-60 {
  opacity: .6;
}

.peer:hover ~ .peer-hover\:border-primary-300 {
  border-color: var(--primary-300);
}

.peer:hover ~ .peer-hover\:border-primary-400 {
  border-color: var(--primary-400);
}

.peer:hover ~ .peer-hover\:border-primary-500 {
  border-color: var(--primary-500);
}

.peer:hover ~ .peer-hover\:border-primary-700 {
  border-color: var(--primary-700);
}

.peer:hover ~ .peer-hover\:bg-primary-600 {
  background-color: var(--primary-600);
}

.peer:focus ~ .peer-focus\:-translate-y-\[0\.75rem\] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:-translate-y-\[0\.9rem\] {
  --tw-translate-y: -.9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:-translate-y-\[1\.15rem\] {
  --tw-translate-y: -1.15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:scale-\[0\.8\] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.peer:focus ~ .peer-focus\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.peer:focus-visible ~ .peer-focus-visible\:border-primary-500 {
  border-color: var(--primary-500);
}

.peer:focus-visible ~ .peer-focus-visible\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.data-\[te-datepicker-cell-disabled\]\:pointer-events-none[data-te-datepicker-cell-disabled] {
  pointer-events: none;
}

.data-\[te-active\]\:-top-\[38px\][data-te-active] {
  top: -38px;
}

.data-\[te-carousel-fade\]\:z-0[data-te-carousel-fade] {
  z-index: 0;
}

.data-\[te-carousel-fade\]\:z-\[1\][data-te-carousel-fade] {
  z-index: 1;
}

.data-\[te-input-state-active\]\:block[data-te-input-state-active] {
  display: block;
}

.data-\[popper-reference-hidden\]\:hidden[data-popper-reference-hidden] {
  display: none;
}

.data-\[te-input-state-active\]\:-translate-y-\[0\.75rem\][data-te-input-state-active] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-input-state-active\]\:-translate-y-\[0\.9rem\][data-te-input-state-active] {
  --tw-translate-y: -.9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-input-state-active\]\:-translate-y-\[1\.15rem\][data-te-input-state-active] {
  --tw-translate-y: -1.15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-sidenav-hidden\=\'false\'\]\:translate-x-0[data-te-sidenav-hidden="false"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-active\]\:scale-100[data-te-active] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-input-state-active\]\:scale-\[0\.8\][data-te-input-state-active] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-select-open\]\:scale-100[data-te-select-open] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-autocomplete-state-open\]\:scale-y-100[data-te-autocomplete-state-open] {
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[data-te-autocomplete-option-disabled\]\:cursor-default[data-data-te-autocomplete-option-disabled], .data-\[te-datepicker-cell-disabled\]\:cursor-default[data-te-datepicker-cell-disabled], .data-\[te-input-disabled\]\:cursor-default[data-te-input-disabled], .data-\[te-select-option-disabled\]\:cursor-default[data-te-select-option-disabled], .data-\[te-select-selected\]\:data-\[te-select-option-disabled\]\:cursor-default[data-te-select-option-disabled][data-te-select-selected] {
  cursor: default;
}

.data-\[te-autocomplete-item-active\]\:bg-black\/5[data-te-autocomplete-item-active] {
  background-color: #0000000d;
}

.data-\[te-input-disabled\]\:bg-\[\#e9ecef\][data-te-input-disabled] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity));
}

.data-\[te-input-multiple-active\]\:bg-black\/5[data-te-input-multiple-active], .data-\[te-input-state-active\]\:bg-black\/5[data-te-input-state-active] {
  background-color: #0000000d;
}

.data-\[te-select-option-selected\]\:bg-black\/\[0\.02\][data-te-select-option-selected] {
  background-color: #00000005;
}

.data-\[te-select-option-selected\]\:data-\[te-input-state-active\]\:bg-black\/5[data-te-input-state-active][data-te-select-option-selected] {
  background-color: #0000000d;
}

.data-\[te-select-selected\]\:data-\[te-select-option-disabled\]\:bg-transparent[data-te-select-option-disabled][data-te-select-selected] {
  background-color: #0000;
}

.data-\[data-te-autocomplete-option-disabled\]\:text-gray-400[data-data-te-autocomplete-option-disabled] {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.data-\[te-datepicker-cell-disabled\]\:text-neutral-300[data-te-datepicker-cell-disabled] {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.data-\[te-select-option-disabled\]\:text-gray-400[data-te-select-option-disabled], .data-\[te-select-selected\]\:data-\[te-select-option-disabled\]\:text-gray-400[data-te-select-option-disabled][data-te-select-selected] {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.data-\[te-autocomplete-state-open\]\:opacity-100[data-te-autocomplete-state-open] {
  opacity: 1;
}

.data-\[te-carousel-fade\]\:opacity-0[data-te-carousel-fade] {
  opacity: 0;
}

.data-\[te-carousel-fade\]\:opacity-100[data-te-carousel-fade], .data-\[te-select-open\]\:opacity-100[data-te-select-open] {
  opacity: 1;
}

.data-\[te-carousel-fade\]\:duration-\[600ms\][data-te-carousel-fade] {
  transition-duration: .6s;
}

.data-\[te-input-state-active\]\:placeholder\:opacity-100[data-te-input-state-active]::placeholder {
  opacity: 1;
}

.data-\[te-datepicker-cell-disabled\]\:hover\:cursor-default:hover[data-te-datepicker-cell-disabled] {
  cursor: default;
}

.group[data-te-datepicker-cell-focused] .group-\[\[data-te-datepicker-cell-focused\]\]\:data-\[te-datepicker-cell-selected\]\:bg-primary[data-te-datepicker-cell-selected] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.group\/validation[data-te-was-validated] .group-data-\[te-was-validated\]\/validation\:mb-4 {
  margin-bottom: 1rem;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-x-0, .group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-l-0 {
  border-left-width: 0;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-r-0 {
  border-right-width: 0;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-t {
  border-top-width: 1px;
}

.group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-l-0 {
  border-left-width: 0;
}

.group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-r-0 {
  border-right-width: 0;
}

.group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-t {
  border-top-width: 1px;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-solid, .group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-solid {
  border-style: solid;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-\[\#14a44d\] {
  --tw-border-opacity: 1;
  border-color: rgb(20 164 77 / var(--tw-border-opacity));
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-\[\#dc4c64\] {
  --tw-border-opacity: 1;
  border-color: rgb(220 76 100 / var(--tw-border-opacity));
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-t-transparent, .group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-t-transparent {
  border-top-color: #0000;
}

.group\/opt[data-te-select-option-group-ref] .group-data-\[te-select-option-group-ref\]\/opt\:pl-7 {
  padding-left: 1.75rem;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[-1px_0_0_\#14a44d\,_0_1px_0_0_\#14a44d\,_0_-1px_0_0_\#14a44d\] {
  --tw-shadow: -1px 0 0 #14a44d, 0 1px 0 0 #14a44d, 0 -1px 0 0 #14a44d;
  --tw-shadow-colored: -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[-1px_0_0_\#3b71ca\,_0_1px_0_0_\#3b71ca\,_0_-1px_0_0_\#3b71ca\] {
  --tw-shadow: -1px 0 0 #3b71ca, 0 1px 0 0 #3b71ca, 0 -1px 0 0 #3b71ca;
  --tw-shadow-colored: -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[-1px_0_0_\#dc4c64\,_0_1px_0_0_\#dc4c64\,_0_-1px_0_0_\#dc4c64\] {
  --tw-shadow: -1px 0 0 #dc4c64, 0 1px 0 0 #dc4c64, 0 -1px 0 0 #dc4c64;
  --tw-shadow-colored: -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[-1px_0_0_\#ffffff\,_0_1px_0_0_\#ffffff\,_0_-1px_0_0_\#ffffff\] {
  --tw-shadow: -1px 0 0 #fff, 0 1px 0 0 #fff, 0 -1px 0 0 #fff;
  --tw-shadow-colored: -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[0_1px_0_0_\#14a44d\] {
  --tw-shadow: 0 1px 0 0 #14a44d;
  --tw-shadow-colored: 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[0_1px_0_0_\#3b71ca\] {
  --tw-shadow: 0 1px 0 0 #3b71ca;
  --tw-shadow-colored: 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[0_1px_0_0_\#dc4c64\] {
  --tw-shadow: 0 1px 0 0 #dc4c64;
  --tw-shadow-colored: 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[0_1px_0_0_\#ffffff\] {
  --tw-shadow: 0 1px 0 0 #fff;
  --tw-shadow-colored: 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[1px_0_0_\#14a44d\,_0_-1px_0_0_\#14a44d\,_0_1px_0_0_\#14a44d\] {
  --tw-shadow: 1px 0 0 #14a44d, 0 -1px 0 0 #14a44d, 0 1px 0 0 #14a44d;
  --tw-shadow-colored: 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[1px_0_0_\#3b71ca\,_0_-1px_0_0_\#3b71ca\,_0_1px_0_0_\#3b71ca\] {
  --tw-shadow: 1px 0 0 #3b71ca, 0 -1px 0 0 #3b71ca, 0 1px 0 0 #3b71ca;
  --tw-shadow-colored: 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[1px_0_0_\#dc4c64\,_0_-1px_0_0_\#dc4c64\,_0_1px_0_0_\#dc4c64\] {
  --tw-shadow: 1px 0 0 #dc4c64, 0 -1px 0 0 #dc4c64, 0 1px 0 0 #dc4c64;
  --tw-shadow-colored: 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[1px_0_0_\#ffffff\,_0_-1px_0_0_\#ffffff\,_0_1px_0_0_\#ffffff\] {
  --tw-shadow: 1px 0 0 #fff, 0 -1px 0 0 #fff, 0 1px 0 0 #fff;
  --tw-shadow-colored: 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group\/validation[data-te-was-validated] .peer:valid ~ .group-data-\[te-was-validated\]\/validation\:peer-valid\:block {
  display: block;
}

.group\/validation[data-te-was-validated] .peer:valid ~ .group-data-\[te-was-validated\]\/validation\:peer-valid\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.group\/validation[data-te-was-validated] .peer:invalid ~ .group-data-\[te-was-validated\]\/validation\:peer-invalid\:block {
  display: block;
}

.group\/validation[data-te-was-validated] .peer:invalid ~ .group-data-\[te-was-validated\]\/validation\:peer-invalid\:text-\[rgb\(220\,76\,100\)\] {
  --tw-text-opacity: 1;
  color: rgb(220 76 100 / var(--tw-text-opacity));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:-translate-y-\[0\.75rem\] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:-translate-y-\[0\.9rem\] {
  --tw-translate-y: -.9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:-translate-y-\[1\.15rem\] {
  --tw-translate-y: -1.15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:scale-\[0\.8\] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-focused] ~ .peer-data-\[te-input-focused\]\:\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.peer[data-te-input-focused] ~ .peer-data-\[te-input-focused\]\:text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:transform-none {
    transform: none;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .motion-reduce\:animate-\[spin_1\.5s_linear_infinite\] {
    animation: 1.5s linear infinite spin;
  }

  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: none;
    }
  }

  .motion-reduce\:animate-\[spinner-grow_1\.5s_linear_infinite\] {
    animation: 1.5s linear infinite spinner-grow;
  }

  .motion-reduce\:animate-none {
    animation: none;
  }

  .motion-reduce\:transition-none {
    transition-property: none;
  }
}

@media (width >= 320px) {
  .xs\:ml-4 {
    margin-left: 1rem;
  }

  .xs\:flex-col {
    flex-direction: column;
  }
}

@media (width >= 768px) {
  .md\:invisible {
    visibility: hidden;
  }

  .md\:bottom-24 {
    bottom: 6rem;
  }

  .md\:z-20 {
    z-index: 20;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:m-16 {
    margin: 4rem;
  }

  .md\:m-4 {
    margin: 1rem;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:ml-24 {
    margin-left: 6rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-3 {
    margin-top: .75rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-12 {
    height: 3rem;
  }

  .md\:h-216 {
    height: 54rem;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:h-52 {
    height: 13rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-\[700px\] {
    height: 700px;
  }

  .md\:h-fit {
    height: fit-content;
  }

  .md\:h-max {
    height: max-content;
  }

  .md\:max-h-\[200px\] {
    max-height: 200px;
  }

  .md\:min-h-50vh {
    min-height: 50vh;
  }

  .md\:min-h-fit {
    min-height: fit-content;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-216 {
    width: 54rem;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-4 {
    width: 1rem;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-\[300px\] {
    width: 300px;
  }

  .md\:w-\[350px\] {
    width: 350px;
  }

  .md\:w-\[400px\] {
    width: 400px;
  }

  .md\:w-\[500px\] {
    width: 500px;
  }

  .md\:w-\[600px\] {
    width: 600px;
  }

  .md\:w-\[800px\] {
    width: 800px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-fit {
    width: fit-content;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:min-w-\[20\%\] {
    min-width: 20%;
  }

  .md\:max-w-144 {
    max-width: 36rem;
  }

  .md\:max-w-\[20\%\] {
    max-width: 20%;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:basis-80 {
    flex-basis: 20rem;
  }

  .md\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-3 {
    gap: .75rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .md\:bg-\[center_top_-30rem\] {
    background-position: 50% -30rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-2 {
    padding: .5rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:pb-12 {
    padding-bottom: 3rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pr-1 {
    padding-right: .25rem;
  }

  .md\:pr-2 {
    padding-right: .5rem;
  }

  .md\:pr-\[17px\] {
    padding-right: 17px;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-5 {
    padding-top: 1.25rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
}

@media (width >= 1024px) {
  .lg\:top-1\/4 {
    top: 25%;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg\:max-w-\[20\%\] {
    max-width: 20%;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 320px) {
  @media not all and (width >= 768px) {
    @media (orientation: landscape) {
      .xs\:max-md\:landscape\:mt-24 {
        margin-top: 6rem;
      }

      .xs\:max-md\:landscape\:h-8 {
        height: 2rem;
      }

      .xs\:max-md\:landscape\:h-\[360px\] {
        height: 360px;
      }

      .xs\:max-md\:landscape\:h-full {
        height: 100%;
      }

      .xs\:max-md\:landscape\:w-8 {
        width: 2rem;
      }

      .xs\:max-md\:landscape\:w-\[475px\] {
        width: 475px;
      }

      .xs\:max-md\:landscape\:flex-row {
        flex-direction: row;
      }
    }
  }

  @media (width <= 825px) {
    @media (orientation: landscape) {
      .min-\[320px\]\:max-\[825px\]\:landscape\:h-auto {
        height: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:min-h-\[305px\] {
        min-height: 305px;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:min-h-\[auto\] {
        min-height: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:min-w-\[auto\] {
        min-width: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:\!flex-row {
        flex-direction: row !important;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:flex-col {
        flex-direction: column;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:\!justify-around {
        justify-content: space-around !important;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:overflow-y-auto {
        overflow-y: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-lg {
        border-radius: .5rem;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-bl-lg {
        border-bottom-left-radius: .5rem;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-bl-none {
        border-bottom-left-radius: 0;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-tr-none {
        border-top-right-radius: 0;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:p-\[10px\] {
        padding: 10px;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:pr-\[10px\] {
        padding-right: 10px;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:text-5xl {
        font-size: 3rem;
        line-height: 1;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:text-\[3rem\] {
        font-size: 3rem;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:font-normal {
        font-weight: 400;
      }
    }
  }
}

.rtl\:\!left-auto:where([dir="rtl"], [dir="rtl"] *) {
  left: auto !important;
}

.rtl\:\!origin-\[50\%_50\%_0\]:where([dir="rtl"], [dir="rtl"] *) {
  transform-origin: 50% 50% 0 !important;
}

.rtl\:\[direction\:rtl\]:where([dir="rtl"], [dir="rtl"] *) {
  direction: rtl;
}

@media (prefers-color-scheme: dark) {
  .dark\:border {
    border-width: 1px;
  }

  .dark\:border-0 {
    border-width: 0;
  }

  .dark\:border-\[\#14a44d\] {
    --tw-border-opacity: 1;
    border-color: rgb(20 164 77 / var(--tw-border-opacity));
  }

  .dark\:border-\[\#4f4f4f\] {
    --tw-border-opacity: 1;
    border-color: rgb(79 79 79 / var(--tw-border-opacity));
  }

  .dark\:border-\[\#dc4c64\] {
    --tw-border-opacity: 1;
    border-color: rgb(220 76 100 / var(--tw-border-opacity));
  }

  .dark\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity));
  }

  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }

  .dark\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity));
  }

  .dark\:border-neutral-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity));
  }

  .dark\:border-neutral-500 {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity));
  }

  .dark\:border-neutral-600 {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity));
  }

  .dark\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity));
  }

  .dark\:border-primary-400 {
    border-color: var(--primary-400);
  }

  .dark\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity));
  }

  .dark\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity));
  }

  .dark\:border-surface-400 {
    border-color: rgb(var(--surface-400));
  }

  .dark\:border-surface-600 {
    border-color: rgb(var(--surface-600));
  }

  .dark\:border-surface-700 {
    border-color: rgb(var(--surface-700));
  }

  .dark\:\!bg-neutral-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity)) !important;
  }

  .dark\:bg-\[\#4f4f4f\] {
    --tw-bg-opacity: 1;
    background-color: rgb(79 79 79 / var(--tw-bg-opacity));
  }

  .dark\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dark\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity));
  }

  .dark\:bg-neutral-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity));
  }

  .dark\:bg-neutral-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
  }

  .dark\:bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
  }

  .dark\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity));
  }

  .dark\:bg-primary-400 {
    background-color: var(--primary-400);
  }

  .dark\:bg-primary-600 {
    background-color: var(--primary-600);
  }

  .dark\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity));
  }

  .dark\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity));
  }

  .dark\:bg-surface-400 {
    background-color: rgb(var(--surface-400));
  }

  .dark\:bg-surface-600 {
    background-color: rgb(var(--surface-600));
  }

  .dark\:bg-surface-600\/60 {
    background-color: rgb(var(--surface-600) / .6);
  }

  .dark\:bg-surface-600\/80 {
    background-color: rgb(var(--surface-600) / .8);
  }

  .dark\:bg-surface-700 {
    background-color: rgb(var(--surface-700));
  }

  .dark\:bg-surface-800 {
    background-color: rgb(var(--surface-800));
  }

  .dark\:bg-surface-900 {
    background-color: rgb(var(--surface-900));
  }

  .dark\:bg-transparent {
    background-color: #0000;
  }

  .dark\:bg-zinc-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity));
  }

  .dark\:bg-zinc-600\/50 {
    background-color: #52525b80;
  }

  .dark\:bg-zinc-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity));
  }

  .dark\:bg-zinc-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
  }

  .dark\:fill-gray-400 {
    fill: #9ca3af;
  }

  .dark\:\!text-primary-400 {
    color: var(--primary-400) !important;
  }

  .dark\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
  }

  .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }

  .dark\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }

  .dark\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }

  .dark\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }

  .dark\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity));
  }

  .dark\:text-neutral-200 {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity));
  }

  .dark\:text-neutral-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity));
  }

  .dark\:text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
  }

  .dark\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity));
  }

  .dark\:text-primary-400 {
    color: var(--primary-400);
  }

  .dark\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
  }

  .dark\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
  }

  .dark\:text-surface-200 {
    color: rgb(var(--surface-200));
  }

  .dark\:text-surface-300 {
    color: rgb(var(--surface-300));
  }

  .dark\:text-surface-400 {
    color: rgb(var(--surface-400));
  }

  .dark\:text-surface-900 {
    color: rgb(var(--surface-900));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:text-white\/70 {
    color: #ffffffb3;
  }

  .dark\:text-white\/80 {
    color: #fffc;
  }

  .dark\:shadow-\[0_4px_9px_-4px_rgba\(59\,113\,202\,0\.5\)\] {
    --tw-shadow: 0 4px 9px -4px #3b71ca80;
    --tw-shadow-colored: 0 4px 9px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:placeholder\:text-gray-200::placeholder {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }

  .dark\:placeholder\:text-surface-500::placeholder {
    color: rgb(var(--surface-500));
  }

  .dark\:checked\:border-primary:checked {
    --tw-border-opacity: 1;
    border-color: hsl(var(--p) / var(--tw-border-opacity));
  }

  .dark\:checked\:bg-primary:checked {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--p) / var(--tw-bg-opacity));
  }

  .dark\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity));
  }

  .dark\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity));
  }

  .dark\:hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity));
  }

  .dark\:hover\:border-primary-300:hover {
    border-color: var(--primary-300);
  }

  .dark\:hover\:border-primary-400:hover {
    border-color: var(--primary-400);
  }

  .dark\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity));
  }

  .dark\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity));
  }

  .dark\:hover\:border-surface-300:hover {
    border-color: rgb(var(--surface-300));
  }

  .dark\:hover\:\!bg-\[\#555\]:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(85 85 85 / var(--tw-bg-opacity)) !important;
  }

  .dark\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-neutral-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-neutral-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-neutral-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-primary-300:hover {
    background-color: var(--primary-300);
  }

  .dark\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity));
  }

  .dark\:hover\:bg-surface-300:hover {
    background-color: rgb(var(--surface-300));
  }

  .dark\:hover\:bg-surface-600\/80:hover {
    background-color: rgb(var(--surface-600) / .8);
  }

  .dark\:hover\:bg-white\/10:hover {
    background-color: #ffffff1a;
  }

  .dark\:hover\:fill-gray-100:hover {
    fill: #f3f4f6;
  }

  .dark\:hover\:text-\[\#3b71ca\]:hover {
    --tw-text-opacity: 1;
    color: rgb(59 113 202 / var(--tw-text-opacity));
  }

  .dark\:hover\:text-primary-400:hover {
    color: var(--primary-400);
  }

  .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:hover\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.2\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.1\)\]:hover {
    --tw-shadow: 0 8px 9px -4px #3b71ca33, 0 4px 18px 0 #3b71ca1a;
    --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:focus\:\!bg-\[\#555\]:focus {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(85 85 85 / var(--tw-bg-opacity)) !important;
  }

  .dark\:focus\:bg-white\/10:focus {
    background-color: #ffffff1a;
  }

  .dark\:focus\:text-\[\#3b71ca\]:focus {
    --tw-text-opacity: 1;
    color: rgb(59 113 202 / var(--tw-text-opacity));
  }

  .dark\:focus\:text-primary-400:focus {
    color: var(--primary-400);
  }

  .dark\:focus\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.2\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.1\)\]:focus {
    --tw-shadow: 0 8px 9px -4px #3b71ca33, 0 4px 18px 0 #3b71ca1a;
    --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:focus\:ring-blue-300\/50:focus {
    --tw-ring-color: #93c5fd80;
  }

  .dark\:focus\:ring-green-300\/50:focus {
    --tw-ring-color: #86efac80;
  }

  .dark\:focus\:ring-orange-300\/50:focus {
    --tw-ring-color: #fdba7480;
  }

  .dark\:focus\:ring-purple-300\/50:focus {
    --tw-ring-color: #d8b4fe80;
  }

  .dark\:focus\:ring-red-300\/50:focus {
    --tw-ring-color: #fca5a580;
  }

  .dark\:focus\:ring-surface-300\/50:focus {
    --tw-ring-color: rgb(var(--surface-300) / .5);
  }

  .dark\:focus\:before\:shadow-\[0px_0px_0px_13px_rgba\(255\,255\,255\,0\.4\)\]:focus:before {
    content: var(--tw-content);
    --tw-shadow: 0px 0px 0px 13px #fff6;
    --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:checked\:focus\:before\:shadow-\[0px_0px_0px_13px_\#3b71ca\]:focus:checked:before {
    content: var(--tw-content);
    --tw-shadow: 0px 0px 0px 13px #3b71ca;
    --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:active\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.2\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.1\)\]:active {
    --tw-shadow: 0 8px 9px -4px #3b71ca33, 0 4px 18px 0 #3b71ca1a;
    --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark\:disabled\:text-neutral-600:disabled {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity));
  }

  .dark\:disabled\:hover\:bg-transparent:hover:disabled {
    background-color: #0000;
  }

  .group[data-te-datepicker-cell-current] .dark\:group-\[\[data-te-datepicker-cell-current\]\]\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }

  .group:not([data-te-datepicker-cell-disabled]):not([data-te-datepicker-cell-selected]):hover .dark\:group-\[\:not\(\[data-te-datepicker-cell-disabled\]\)\:not\(\[data-te-datepicker-cell-selected\]\)\:hover\]\:bg-white\/10, .group:not([data-te-datepicker-cell-selected])[data-te-datepicker-cell-focused] .dark\:group-\[\:not\(\[data-te-datepicker-cell-selected\]\)\[data-te-datepicker-cell-focused\]\]\:bg-white\/10 {
    background-color: #ffffff1a;
  }

  .group[data-te-datepicker-cell-disabled] .dark\:group-\[\[data-te-datepicker-cell-disabled\]\]\:text-neutral-500 {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
  }

  .peer:hover ~ .dark\:peer-hover\:border-primary-300 {
    border-color: var(--primary-300);
  }

  .peer:hover ~ .dark\:peer-hover\:border-primary-400 {
    border-color: var(--primary-400);
  }

  .peer:hover ~ .dark\:peer-hover\:bg-primary-300 {
    background-color: var(--primary-300);
  }

  .peer:focus ~ .dark\:peer-focus\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }

  .peer:focus ~ .dark\:peer-focus\:text-primary {
    --tw-text-opacity: 1;
    color: hsl(var(--p) / var(--tw-text-opacity));
  }

  .peer:focus-visible ~ .dark\:peer-focus-visible\:border-primary-400 {
    border-color: var(--primary-400);
  }

  .dark\:data-\[te-autocomplete-item-active\]\:bg-white\/30[data-te-autocomplete-item-active] {
    background-color: #ffffff4d;
  }

  .dark\:data-\[te-buttons-timepicker\]\:bg-zinc-700[data-te-buttons-timepicker] {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity));
  }

  .dark\:data-\[te-input-disabled\]\:bg-zinc-600[data-te-input-disabled] {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity));
  }

  .dark\:data-\[te-input-multiple-active\]\:bg-white\/30[data-te-input-multiple-active], .dark\:data-\[te-input-state-active\]\:bg-white\/30[data-te-input-state-active], .dark\:data-\[te-select-option-selected\]\:data-\[te-input-state-active\]\:bg-white\/30[data-te-input-state-active][data-te-select-option-selected] {
    background-color: #ffffff4d;
  }

  .dark\:data-\[te-select-option-disabled\]\:text-gray-400[data-te-select-option-disabled] {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}

.\[\&\.ps--clicking\]\:\!bg-\[\#eee\].ps--clicking {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity)) !important;
}

.\[\&\.ps--clicking\]\:\!opacity-90.ps--clicking {
  opacity: .9 !important;
}

@media (prefers-color-scheme: dark) {
  .dark\:\[\&\.ps--clicking\]\:\!bg-\[\#555\].ps--clicking {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(85 85 85 / var(--tw-bg-opacity)) !important;
  }
}

.\[\&\:\:-webkit-scrollbar-button\]\:block::-webkit-scrollbar-button {
  display: block;
}

.\[\&\:\:-webkit-scrollbar-button\]\:h-0::-webkit-scrollbar-button {
  height: 0;
}

.\[\&\:\:-webkit-scrollbar-button\]\:bg-transparent::-webkit-scrollbar-button {
  background-color: #0000;
}

.\[\&\:\:-webkit-scrollbar-thumb\]\:h-\[50px\]::-webkit-scrollbar-thumb {
  height: 50px;
}

.\[\&\:\:-webkit-scrollbar-thumb\]\:rounded::-webkit-scrollbar-thumb {
  border-radius: .25rem;
}

.\[\&\:\:-webkit-scrollbar-thumb\]\:bg-\[\#999\]::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.\[\&\:\:-webkit-scrollbar-track-piece\]\:rounded-none::-webkit-scrollbar-track-piece {
  border-radius: 0;
}

.\[\&\:\:-webkit-scrollbar-track-piece\]\:rounded-l::-webkit-scrollbar-track-piece {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.\[\&\:\:-webkit-scrollbar-track-piece\]\:bg-transparent::-webkit-scrollbar-track-piece {
  background-color: #0000;
}

.\[\&\:\:-webkit-scrollbar\]\:h-1::-webkit-scrollbar {
  height: .25rem;
}

.\[\&\:\:-webkit-scrollbar\]\:w-1::-webkit-scrollbar {
  width: .25rem;
}

.hover\:\[\&\:not\(\[data-te-autocomplete-option-disabled\]\)\]\:bg-black\/5:not([data-te-autocomplete-option-disabled]):hover {
  background-color: #0000000d;
}

@media (prefers-color-scheme: dark) {
  .dark\:hover\:\[\&\:not\(\[data-te-autocomplete-option-disabled\]\)\]\:bg-white\/30:not([data-te-autocomplete-option-disabled]):hover {
    background-color: #ffffff4d;
  }
}

.\[\&\:not\(\[data-te-input-placeholder-active\]\)\]\:placeholder\:opacity-0:not([data-te-input-placeholder-active])::placeholder {
  opacity: 0;
}

.hover\:\[\&\:not\(\[data-te-select-option-disabled\]\)\]\:bg-black\/5:not([data-te-select-option-disabled]):hover {
  background-color: #0000000d;
}

@media (prefers-color-scheme: dark) {
  .dark\:hover\:\[\&\:not\(\[data-te-select-option-disabled\]\)\]\:bg-white\/30:not([data-te-select-option-disabled]):hover {
    background-color: #ffffff4d;
  }
}

.\[\&\:nth-child\(odd\)\]\:bg-neutral-50:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

@media (prefers-color-scheme: dark) {
  .\[\&\:nth-child\(odd\)\]\:dark\:bg-neutral-700:nth-child(odd) {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
  }
}

.\[\&\>p\]\:py-2 > p {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.\[\&\>svg\]\:pointer-events-none > svg {
  pointer-events: none;
}

.\[\&\>svg\]\:mx-auto > svg {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>svg\]\:h-4 > svg {
  height: 1rem;
}

.\[\&\>svg\]\:h-5 > svg {
  height: 1.25rem;
}

.\[\&\>svg\]\:h-6 > svg {
  height: 1.5rem;
}

.\[\&\>svg\]\:w-4 > svg {
  width: 1rem;
}

.\[\&\>svg\]\:w-5 > svg {
  width: 1.25rem;
}

.\[\&\>svg\]\:w-6 > svg {
  width: 1.5rem;
}

.\[\&\>svg\]\:rotate-180 > svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>svg\]\:fill-neutral-500 > svg {
  fill: #737373;
}

.\[\&\>svg\]\:text-white > svg {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .dark\:\[\&\>svg\]\:fill-white > svg {
    fill: #fff;
  }
}

.\[\&\>ul\>li\:before\]\:absolute > ul > li:before {
  position: absolute;
}

.\[\&\>ul\>li\:before\]\:left-0 > ul > li:before {
  left: 0;
}

.\[\&\>ul\>li\:before\]\:text-amber-500 > ul > li:before {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.\[\&\>ul\>li\:before\]\:content-\[\'✔\'\] > ul > li:before {
  --tw-content: "✔";
  content: var(--tw-content);
}

.\[\&\>ul\>li\]\:relative > ul > li {
  position: relative;
}

.\[\&\>ul\>li\]\:pl-5 > ul > li {
  padding-left: 1.25rem;
}

.\[\&\>ul\]\:list-none > ul {
  list-style-type: none;
}

.\[\&\[data-te-dropdown-show\]\]\:block[data-te-dropdown-show] {
  display: block;
}
/*# sourceMappingURL=index.a0fea3fb.css.map */
